export const INTERNAL_LINKS = {
  homepage: "/",
  privacyPolicy: "/policies/privacy-policy",
  returnPolicy: "/policies/return-policy",
  termsAndConditions: "/policies/terms-and-conditions",
  products: "/products",
  collections: "/collections",
  newProducts: "/products?s=CREATED",
  profile: "/profile",
  search: "/search",
  cart: "/cart",
  orders: "/orders",
  checkout: "/checkout",
  wishlist: "/wishlist",
  purchases: "/purchases",
};
