import * as React from "react";
import { StoreProvider } from "./src/context/store-context";
import { SearchProvider } from "./src/context/search-provider";
import "./src/styles/global.scss";
import { GatsbyBrowser } from "gatsby";
import { MsalAuthenticationProvider } from "./src/context/msal-authentifacation-context";
import { MDRTStoreCreationContextProvider } from "./src/context/mdrt-store-creation-context";

export const wrapRootElement: GatsbyBrowser["wrapRootElement"] = ({
  element,
}) => {
  return (
    <StoreProvider>
      <SearchProvider>
        <MDRTStoreCreationContextProvider>
          <MsalAuthenticationProvider>{element}</MsalAuthenticationProvider>
        </MDRTStoreCreationContextProvider>
      </SearchProvider>
    </StoreProvider>
  );
};
