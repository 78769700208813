import { CustomerYears, MDRTCustomer } from "../../mdrt/types";
import { ShopifyCustomer } from "../../shopify/types";
import { NOT_ENTERED } from "../../../utils/constants/constants";
import getMDRTCustomerPhone from "./get-mdrt-customer-phone";

export const convertToShopifyCustomer = (
  personId: number,
  mdrtCustomer: MDRTCustomer,
  history: CustomerYears[]
): ShopifyCustomer => {
  const companyName = getCompany(
    mdrtCustomer.personalCompany,
    mdrtCustomer.companyName
  );
  const customer: ShopifyCustomer = {
    firstName: mdrtCustomer.firstName,
    lastName: mdrtCustomer.lastName,
    email: mdrtCustomer.primaryEmail,
    phone: getMDRTCustomerPhone(mdrtCustomer),
    personId: personId,
    mdrtid: mdrtCustomer.mdrtid,
    defaultAddress: {
      address1: mdrtCustomer.line1,
      city: mdrtCustomer.city,
      country: mdrtCustomer.country,
      zip: mdrtCustomer.postalCode,
      province: mdrtCustomer.stateProvince,
      firstName: mdrtCustomer.firstName,
      lastName: mdrtCustomer.lastName,
      company: companyName,
    },
    additionalInfo: {
      fullName: `${mdrtCustomer.firstName} ${mdrtCustomer.lastName}`
        .replace(NOT_ENTERED, "")
        .trim(),
      designation: [mdrtCustomer?.designation1, mdrtCustomer?.designation2]
        .filter((d) => d)
        .join(", ")
        .trim(),
      levelOfMembership: mdrtCustomer.levelOfMembership,
      memberStatus: mdrtCustomer.memberStatus,
      memberType: mdrtCustomer.memberType,
      history: history,
      personId: personId,
      mdrtId: mdrtCustomer.mdrtid,
      companyId:
        mdrtCustomer?.companyID > 0
          ? mdrtCustomer.companyID.toString()
          : companyName,
    },
  };
  return customer;
};

const getCompany = (personalCompany: string, companyName: string): string => {
  if (personalCompany?.length > 0) {
    return personalCompany;
  }
  if (companyName?.length > 0) {
    return companyName;
  }
  return "";
};
