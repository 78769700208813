import React, {
  createContext,
  ReactNode,
  useEffect,
  useReducer,
  useState,
} from "react";
import {
  buildClient,
  Config,
  CustomAttribute,
  LineItemToAdd,
} from "shopify-buy";
import * as Sentry from "@sentry/react";
import { MetafieldsService } from "../../services/shopify/metafields.service";
import { SESSION_STORAGE_KEYS } from "../../utils/constants/storageKeys";
import {
  getSessionCustomerProfile,
  setSessionCustomerProfile,
} from "../../utils/sessionStorage";
import {
  CartItem,
  CustomerProfileViewModel,
  ShopCart,
} from "../../utils/types";
import { PlaqueInfo } from "../../components/Plaque/types";
import { ShopifyCustomerService } from "../../services/shopify/customers.service";
import {
  ToastInfo,
  ToastVariant,
} from "../../components/ToastsController/types";
import { useTranslation } from "gatsby-plugin-react-i18next";
import CartIcon from "../../icons/cart";
import { CustomAttributeKeys } from "../../utils/constants/constants";
import { plaqueInfoReducer } from "../../utils/helper/setPlaqueInfoHelpers";
import { getCurrentShopifyCustomer } from "../../services/internal/shopify/get-current-customer";
import {
  pushDataLayerAddRemoveCartItem,
  pushDataLayerAddToCart,
} from "../../utils/helper/googleAnalytics";
import {
  AdditionalInfo,
  additionalInfoFields,
} from "../../services/shopify/types";
import { getWishlist } from "../../services/internal/wishlist";
import {
  ShopifyProduct,
  ShopifyProductVariant,
} from "../../types/internal.types";
import { pick } from "lodash";
import { useOnlineStateLogger } from "./hooks";
import { getPersonId } from "../../utils/helper/accountUtils";
import {
  getAnonymousUserCheckoutId,
  getCurrentUserCheckoutId,
  removeAnonymousUserCheckoutId,
  setCurrentUserCheckoutId,
} from "../../utils/helper/checkoutUtils";
import { wrapPromiseWithRetries } from "../../utils/wrapPromiseWithRetries";

const client = buildClient({
  domain: process.env.GATSBY_SHOPIFY_STORE_URL_PROXY ?? "",
  storefrontAccessToken: process.env.GATSBY_STOREFRONT_ACCESS_TOKEN ?? "",
} as Config);

const cart: ShopCart = {
  checkoutUrl: "",
  id: "",
  lineItemCount: 0,
  lineItems: Array<CartItem>(),
  subtotalPrice: "",
  completedAt: null,
  webUrl: "",
  subtotalPriceV2: {
    amount: "",
    currencyCode: "",
  },
  totalPriceV2: {
    amount: "",
    currencyCode: "",
  },
  currencyCode: "",
};

const plaqueInfo: PlaqueInfo = {
  name: "",
  designation: "",
  year: "",
  yearArr: [],
  comma: true,
  photo: null,
  yearSum: "",
  templateUrl: "",
  isGrayscale: false,
};

const customerProfile: AdditionalInfo = {
  fullName: "",
  designation: "",
  levelOfMembership: "",
  memberStatus: "",
  memberType: "",
  history: [],
  personId: 0,
  mdrtId: 0,
  companyId: "",
};

const defaultValues = {
  cart: [],
  isOpen: false,
  loading: false,
  setLoading: (loading: boolean) => {
    return;
  },
  onOpen: () => null,
  onClose: () => null,
  addVariantToCart: (
    product: ShopifyProduct,
    variant: ShopifyProductVariant,
    quantity: string,
    isMaxQuantitySurpassed: boolean,
    fullName: string,
    designation: string,
    initials: string,
    photoSrc: string | null,
    photoName: string | null,
    useCommaAfterName?: boolean,
    year?: string,
    ringSize?: string,
    plaqueTemplateDataUrl?: string,
    purchaseFor?: AdditionalInfo
  ): Promise<void> => {
    return Promise.resolve();
  },
  removeLineItem: (
    item: CartItem,
    id: string,
    itemId: string
  ): Promise<void> => {
    return Promise.resolve();
  },
  updateLineItem: (
    checkoutId: string,
    lineItemId: string,
    quantity: string
  ): Promise<void> => {
    return Promise.resolve();
  },
  setAuthenticationLoading: (isLoading: boolean) => {
    return;
  },
  isAuthenticatingUser: true,
  toastMessages: [] as ToastInfo[],
  addToast: (toast: ToastInfo) => {
    return;
  },
  removeToast: (toastId: number) => {
    return;
  },
  client,
  checkout: cart,
  plaqueInfo: plaqueInfo,
  setPlaqueInfo: (plaqueInfoReducer: any) => {
    return;
  },
  additionalInfoLoading: false,
  setAdditionalInfoLoading: (additionalInfoLoading: boolean) => {
    return;
  },
  wishlist: undefined as string[] | undefined,
  setWishlist: (shopifyIds: string[]) => {
    return;
  },
  isGlobalSearchOpen: false,
  setIsGlobalSearchOpen: (isGlobalSearchOpen: boolean) => {
    return;
  },
  isImpersonating: false,
  customerProfile,
  setCustomerProfile: (profile: AdditionalInfo) => {
    return;
  },
  customerAccessToken: null as string | null,
  setCustomerAccessToken: (token: string) => {
    return;
  },
  googleApiLoaded: false,
  setGoogleApiLoaded: (googleApiLoaded: boolean) => {
    return;
  },
};

export const StoreContext = createContext(defaultValues);

export const StoreProvider = ({ children }: { children: ReactNode }) => {
  const { t } = useTranslation();
  const [checkout, setCheckout] = useState<ShopCart>(defaultValues.checkout);
  const [loading, setLoading] = useState(false);
  const [isAuthenticatingUser, setIsAuthenticatingUser] = useState(
    defaultValues.isAuthenticatingUser
  );
  const [additionalInfoLoading, setAdditionalInfoLoading] = useState(false);
  const [toastMessages, setToastMessages] = useState<ToastInfo[]>(
    defaultValues.toastMessages
  );
  const [plaqueInfo, setPlaqueInfo] = useReducer(
    plaqueInfoReducer,
    defaultValues.plaqueInfo
  );
  const [isGlobalSearchOpen, setIsGlobalSearchOpen] = useState(false);
  const [isImpersonating, setIsImpersonating] = useState(
    defaultValues.isImpersonating
  );
  const [customerProfile, setCustomerProfile] = useState<AdditionalInfo>(
    defaultValues.customerProfile
  );
  const [wishlist, setWishlist] = useState(defaultValues.wishlist);
  const [customerAccessToken, setCustomerAccessToken] = useState<string | null>(
    defaultValues.customerAccessToken
  );
  const [googleApiLoaded, setGoogleApiLoaded] = useState<boolean>(false);

  const addToast = (toast: ToastInfo) =>
    setToastMessages((prev) => [toast, ...prev]);
  const removeToast = (id: number) =>
    setToastMessages((prev) => prev.filter((toast) => toast.id !== id));

  const setAuthenticationLoading = (isLoading: boolean) =>
    setIsAuthenticatingUser(isLoading);

  const setCheckoutItem = (checkout: ShopCart) => {
    if (window) {
      setCurrentUserCheckoutId(checkout.id.toString());
    }

    setCheckout(checkout);
  };

  useEffect(() => {
    const initializeCheckout = async () => {
      const anonymousCheckoutId = getAnonymousUserCheckoutId();
      const currentCheckoutId = getCurrentUserCheckoutId();

      const isAnonymousUserCartTransferableToCurrentUser = async () => {
        if (!currentCheckoutId) {
          return true;
        }

        if (!anonymousCheckoutId || !getPersonId()) {
          return false;
        }

        const anonymousUserCheckout = await wrapPromiseWithRetries(
          client.checkout.fetch(anonymousCheckoutId)
        ).catch(() => null);

        return (
          anonymousUserCheckout != null &&
          anonymousUserCheckout?.lineItems.length > 0
        );
      };
      if (
        (await isAnonymousUserCartTransferableToCurrentUser()) &&
        anonymousCheckoutId
      ) {
        removeAnonymousUserCheckoutId();
        setCurrentUserCheckoutId(anonymousCheckoutId);
      }

      const existingCheckoutId = getCurrentUserCheckoutId();
      setLoading(true);
      if (existingCheckoutId && existingCheckoutId !== `null`) {
        try {
          const existingCheckout = await wrapPromiseWithRetries(
            client.checkout.fetch(existingCheckoutId)
          );
          if (!existingCheckout.completedAt) {
            setCheckoutItem(existingCheckout as ShopCart);

            await wrapPromiseWithRetries(
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              client.checkout.updateAttributes(existingCheckout.id.toString(), {
                allowPartialAddresses: true,
                customAttributes: [],
                note: `Person ID: ${getPersonId()}`,
              })
            );

            setLoading(false);
            return;
          }
        } catch (e) {
          /* empty */
        }
      }

      const newCheckout = await wrapPromiseWithRetries(
        client.checkout.create()
      );

      await wrapPromiseWithRetries(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        client.checkout.updateAttributes(newCheckout.id.toString(), {
          allowPartialAddresses: true,
          customAttributes: [],
          note: `Person ID: ${getPersonId()}`,
        })
      );
      setCheckoutItem(newCheckout as ShopCart);
      setLoading(false);
    };

    if (window && !isAuthenticatingUser && !checkout?.id) {
      initializeCheckout().catch((e) => Sentry.captureException(e));
    }
  }, [getPersonId(), window, isAuthenticatingUser]);

  useEffect(() => {
    setIsImpersonating(
      !!sessionStorage.getItem(SESSION_STORAGE_KEYS.impersonatePersonId)
    );
    setWishlist(getWishlist());
  }, []);

  useOnlineStateLogger();

  const addVariantToCart = async (
    product: ShopifyProduct,
    variant: ShopifyProductVariant,
    quantity: string,
    isMaxQuantitySurpassed: boolean,
    fullName: string,
    designation: string,
    initials: string,
    photoSrc: string | null,
    photoName: string | null,
    useCommaAfterName?: boolean,
    year?: string,
    ringSize?: string,
    plaqueTemplateDataUrl?: string,
    purchaseFor?: AdditionalInfo
  ) => {
    setLoading(true);

    const checkoutID = checkout.id;
    const customAttributes: CustomAttribute[] = [
      {
        key: CustomAttributeKeys.FULL_NAME,
        value: fullName,
      },
      {
        key: CustomAttributeKeys.DESIGNATION,
        value: designation,
      },
      {
        key: CustomAttributeKeys.INITIALS,
        value: initials,
      },
      {
        key: CustomAttributeKeys.YEAR,
        value: year ?? "",
      },
      {
        key: CustomAttributeKeys.PRODUCT_TYPE,
        value: product.productType,
      },
      {
        key: CustomAttributeKeys.RING_SIZE,
        value: ringSize ?? "",
      },
    ].filter((attribute) => attribute.value.length > 0);

    const customer = getSessionCustomerProfile() as CustomerProfileViewModel;
    if (customer && customer.personId) {
      const customerAccessToken = sessionStorage.getItem(
        SESSION_STORAGE_KEYS.customerAccessToken
      );
      if (!customerAccessToken) {
        const shopifyCustomersService = new ShopifyCustomerService();
        const customer = await getCurrentShopifyCustomer();
        if (customer?.email) {
          const loginResult = await shopifyCustomersService.login({
            email: customer.email,
            personId: customer.personId,
          });

          if (loginResult.customerAccessToken?.accessToken) {
            sessionStorage.setItem(
              SESSION_STORAGE_KEYS.customerAccessToken,
              loginResult.customerAccessToken?.accessToken
            );
          }
        }
      }

      setCustomerAccessToken(
        sessionStorage.getItem(SESSION_STORAGE_KEYS.customerAccessToken)
      );

      if (!purchaseFor || purchaseFor.personId === customer.personId) {
        updateCustomerProfileIfNeeded(customer, fullName, designation);
      }

      if (photoSrc) {
        customAttributes.push({
          key: CustomAttributeKeys.MEMBER_PHOTO,
          value: photoSrc,
        });
      }
      if (photoName) {
        customAttributes.push({
          key: CustomAttributeKeys.UPLOADED_PHOTO,
          value: photoName,
        });
      }
      if (plaqueTemplateDataUrl?.length) {
        customAttributes.push({
          key: CustomAttributeKeys.PLAQUE_TEMPLATE_URL,
          value: plaqueTemplateDataUrl,
        });
      }
      if (useCommaAfterName !== undefined) {
        customAttributes.push({
          key: CustomAttributeKeys.USE_COMMA_AFTER_NAME,
          value: JSON.stringify(useCommaAfterName),
        });
      }

      if (purchaseFor) {
        customAttributes.push({
          key: CustomAttributeKeys.RECIPIENT,
          value: `${purchaseFor.fullName} (${purchaseFor.mdrtId}; ${purchaseFor.companyId})`,
        });
      }
    }

    const lineItemsToUpdate: LineItemToAdd[] = [
      {
        variantId: variant.storefrontId ?? "",
        quantity: parseInt(quantity, 10),
        customAttributes: customAttributes,
      },
    ];

    return wrapPromiseWithRetries(
      client.checkout
        .addLineItems(checkoutID, lineItemsToUpdate)
        .then((res) => {
          setCheckout(res as ShopCart);
          addToast({
            id: Date.now(),
            message: isMaxQuantitySurpassed
              ? t("Maximum available quantity has been added to your cart.")
              : t("Your cart has been updated."),
            icon: <CartIcon />,
            variant: ToastVariant.success,
          });
          pushDataLayerAddToCart(product, variant, parseInt(quantity, 10));
        })
    )
      .catch((e) => {
        Sentry.captureException(e);
        addToast({
          id: Date.now(),
          message: t("Failed to update your cart, please try again."),
          icon: <CartIcon />,
          variant: ToastVariant.error,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const removeLineItem = async (
    item: CartItem,
    checkoutID: string | number,
    lineItemID: string
  ) => {
    setLoading(true);

    const res = await wrapPromiseWithRetries(
      client.checkout.removeLineItems(checkoutID, [lineItemID])
    );
    setCheckout(res as ShopCart);
    setLoading(false);
    pushDataLayerAddRemoveCartItem(item, false, item?.quantity);
  };

  const updateLineItem = async (
    checkoutId: string | number,
    lineItemId: string,
    quantity: string
  ): Promise<void> => {
    setLoading(true);

    const lineItemsToUpdate = [
      { id: lineItemId, quantity: parseInt(quantity, 10) },
    ];

    const res = await wrapPromiseWithRetries(
      client.checkout.updateLineItems(checkoutId, lineItemsToUpdate)
    );
    setCheckout(res as ShopCart);
    setLoading(false);
  };

  const updateCustomerProfileIfNeeded = async (
    customer: CustomerProfileViewModel,
    fullName: string,
    designation: string
  ) => {
    const metafields: {
      namespace: string;
      key: string;
      ownerId: string;
      type: string;
      value: string;
    }[] = [];

    let needsUpdate = false;
    const profile = pick(customer, additionalInfoFields);
    if (fullName.length && fullName !== profile.fullName) {
      profile.fullName = fullName;
      needsUpdate = true;
    }
    if (designation.length && designation !== profile.designation) {
      profile.designation = designation;
      needsUpdate = true;
    }

    if (needsUpdate) {
      metafields.push({
        namespace: "customer",
        key: "additionalInfo",
        ownerId: customer.id,
        type: "json",
        value: JSON.stringify(profile),
      });

      profile.mainFullName = customer.mainFullName;
      setSessionCustomerProfile(profile as CustomerProfileViewModel);
    }

    if (metafields.length > 0) {
      const metafieldsService = new MetafieldsService();
      await metafieldsService.setMetafieldsOfCustomer({
        metafields,
      });
    }
  };

  const value = {
    ...defaultValues,
    addVariantToCart,
    removeLineItem,
    updateLineItem,
    setAuthenticationLoading,
    isAuthenticatingUser,
    wishlist,
    setWishlist,
    checkout,
    loading,
    toastMessages,
    addToast,
    removeToast,
    setPlaqueInfo,
    plaqueInfo,
    additionalInfoLoading,
    setAdditionalInfoLoading,
    setIsGlobalSearchOpen,
    isGlobalSearchOpen,
    setLoading,
    isImpersonating,
    customerProfile,
    setCustomerProfile,
    customerAccessToken,
    setCustomerAccessToken,
    googleApiLoaded,
    setGoogleApiLoaded,
  };

  return (
    <StoreContext.Provider value={value}>{children}</StoreContext.Provider>
  );
};
