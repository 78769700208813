export const SESSION_STORAGE_KEYS = {
  isGridLayoutKey: "isUserUsesGridLayout",
  customerProfileKey: "customer-profile",
  staffCustomerProfileKey: "staff-customer-profile",
  staffСustomerAccessToken: "staff-customer-access-token",
  customerAccessToken: "accessToken",
  logoutCount: "logoutCount",
  msal: "MSAL",
  msalLogout: "msal-logout",
  impersonatePersonId: "impersonate-person-id",
  hasGracePeriod: "has-grace-period",
};

export const LOCAL_STORAGE_KEYS = {
  logoutCount: "logoutCount",
  checkoutId: (personId = 0) => `shopify_checkout_id_${personId}`,
  wishlist: "wishlist",
};
