import {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import * as Sentry from "@sentry/react";
import Cookies from "js-cookie";
import { msalLogout, ssoSilent } from "../../../services/internal/msal";
import {
  AdditionalInfo,
  LoginRequest,
  ShopifyCustomer,
} from "../../../services/shopify/types";
import { ShopifyCustomerService } from "../../../services/shopify/customers.service";
import { extractAdditionalInfo } from "../../../services/internal/shopify/extract-additional-info";
import { syncCurrentCustomerAdditionalInfo } from "../../../services/internal/shopify/sync-current-customer-additional-info";
import { createShopifyCustomer } from "../../../services/internal/shopify/create-shopify-customer";
import { RefreshCustomerInfoResponse } from "../../../utils/types";
import { getPersonId } from "../../../utils/helper/accountUtils";
import { getSessionCustomerProfile } from "../../../utils/sessionStorage";
import { SESSION_STORAGE_KEYS } from "../../../utils/constants/storageKeys";
import { useOnStopImpersonate } from "../../../utils/helper/impersonationHelpers";
import { MSAL_USERNAME, msalInstance } from "../../../authConfig";
import { StoreContext } from "../../store-context";
import { useMDRTStoreCreationContext } from "../../mdrt-store-creation-context";
import { ExceptionTypes } from "../../../exceptions";
import { AccountInfo, EventMessage, EventType } from "@azure/msal-browser";

export const useLogin = () => {
  const { setAuthenticationLoading } = useContext(StoreContext);
  const { preventLogin, openModal, closeModal } = useMDRTStoreCreationContext();
  const [shopifyCustomer, setShopifyCustomer] = useState<ShopifyCustomer>();
  const [customerAccessToken, setCustomerAccessToken] = useState<string>();
  const showErrorAndLogout = useShowErrorAndLogout();
  const { t } = useTranslation();

  const refreshCustomerInfo = useRefreshCustomerInfo({
    setShopifyCustomer,
    setCustomerAccessToken,
  });
  const silentLogin = useSilentLogin();

  const loginSuccessPromiseRef = useRef<Promise<AccountInfo | null> | null>(
    null
  );
  useEffect(() => {
    loginSuccessPromiseRef.current = new Promise((resolve) => {
      msalInstance.addEventCallback((message: EventMessage) => {
        if (message.eventType == EventType.LOGIN_SUCCESS) {
          msalInstance.setActiveAccount(message.payload as AccountInfo);
          resolve(message.payload as AccountInfo);
        }
      });
    });
  }, []);

  useEffect(() => {
    if (preventLogin) {
      setAuthenticationLoading(false);
      return;
    }

    if (
      sessionStorage.getItem(SESSION_STORAGE_KEYS.msalLogout) === "inProgress"
    ) {
      sessionStorage.removeItem(SESSION_STORAGE_KEYS.msal);
      return;
    }

    const loginAndSyncCustomerData = async () => {
      setAuthenticationLoading(true);

      let activeAccount = msalInstance.getActiveAccount();
      if (!activeAccount) {
        activeAccount = await Promise.any([
          loginSuccessPromiseRef.current,
          silentLogin(),
        ]);
      }

      if (activeAccount) {
        const response = await refreshCustomerInfo();
        if (!response.success) {
          if (response.errorMessage) {
            showErrorAndLogout(response.errorMessage);
          } else {
            msalLogout();
          }
        }
      }

      closeModal();
    };

    loginAndSyncCustomerData()
      .catch((e) => {
        if (e.type === ExceptionTypes.UserError) {
          openModal(e);
        } else {
          console.error(e);
          showErrorAndLogout(t("An unexpected error has occurred."));
        }
      })
      .finally(() => setAuthenticationLoading(false));
  }, [preventLogin]);

  return {
    shopifyCustomer,
    customerAccessToken,
  };
};

const useRefreshCustomerInfo = ({
  setShopifyCustomer,
  setCustomerAccessToken,
}: {
  setShopifyCustomer: Dispatch<SetStateAction<ShopifyCustomer | undefined>>;
  setCustomerAccessToken: Dispatch<SetStateAction<string | undefined>>;
}) => {
  const { setAdditionalInfoLoading, setCustomerProfile, isImpersonating } =
    useContext(StoreContext);
  const showErrorAndStopImpersonate = useShowErrorAndStopImpersonate();
  const showErrorAndLogout = useShowErrorAndLogout();
  const { t } = useTranslation();

  return useCallback(async (): Promise<RefreshCustomerInfoResponse> => {
    const personId = getPersonId();
    Cookies.set(MSAL_USERNAME, personId.toString());
    const sessionCustomer = getSessionCustomerProfile();

    if (sessionCustomer?.mdrtId) {
      Sentry.setUser({ id: sessionCustomer.mdrtId.toString() });
    }

    if (sessionCustomer?.personId !== personId) {
      const shopifyCustomersService = new ShopifyCustomerService();
      const customerResponse =
        await shopifyCustomersService.getCustomerByPersonId(personId);

      if (customerResponse.userErrors) {
        return {
          success: false,
          errorMessage: customerResponse.userErrors[0].message,
        };
      }

      let customer = customerResponse.customer;

      if (!customer?.id) {
        setAdditionalInfoLoading(true);

        const response = await createCustomer({
          personId,
          setShopifyCustomer,
          setCustomerAccessToken,
        });

        if (!response) {
          if (isImpersonating) {
            showErrorAndStopImpersonate(t("An unexpected error has occurred."));
          } else {
            showErrorAndLogout(t("An unexpected error has occurred."));
          }
        }

        setAdditionalInfoLoading(false);

        return { success: true };
      } else {
        customer = extractAdditionalInfo(customer);
        setShopifyCustomer(customer);
        await loginShopifyUser({
          setCustomerAccessToken,
          request: {
            email: customer.email,
            personId: customer.personId,
          },
        });
        const syncedAdditionalInfo = await syncCurrentCustomerAdditionalInfo(
          customer.id ?? "",
          personId,
          customer.additionalInfo
        );

        setAdditionalInfoLoading(true);
        if (syncedAdditionalInfo && customer) {
          const customerWithAdditionalInfo = {
            ...customer,
            additionalInfo: syncedAdditionalInfo,
          };

          setShopifyCustomer(customerWithAdditionalInfo);
        }
        setAdditionalInfoLoading(false);

        return { success: true };
      }
    }

    setCustomerProfile(sessionCustomer as unknown as AdditionalInfo);
    return { success: !!sessionCustomer };
  }, []);
};

const useSilentLogin = () => {
  return useCallback(async () => {
    try {
      await ssoSilent(0);
      const accounts = msalInstance.getAllAccounts();

      if (accounts?.length && accounts[0]) {
        msalInstance.setActiveAccount(accounts[0]);
        return accounts[0];
      } else {
        return null;
      }
    } catch (error) {
      console.error("MSAL ERROR", error);
      window.location.href = "/";
      return null;
    }
  }, []);
};

const createCustomer = async ({
  personId,
  setShopifyCustomer,
  setCustomerAccessToken,
}: {
  personId: number;
  setShopifyCustomer: Dispatch<SetStateAction<ShopifyCustomer | undefined>>;
  setCustomerAccessToken: Dispatch<SetStateAction<string | undefined>>;
}) => {
  const customer = await createShopifyCustomer(personId);

  if (customer?.email) {
    setShopifyCustomer(customer);
    await loginShopifyUser({
      setCustomerAccessToken,
      request: {
        email: customer.email,
        personId: personId,
      },
    });
  }

  return customer;
};

const loginShopifyUser = async ({
  request,
  setCustomerAccessToken,
}: {
  request: LoginRequest;
  setCustomerAccessToken: Dispatch<SetStateAction<string | undefined>>;
}) => {
  const shopifyCustomersService = new ShopifyCustomerService();
  const loginResult = await shopifyCustomersService.login(request);
  setCustomerAccessToken(loginResult?.customerAccessToken?.accessToken);

  window.dispatchEvent(
    new CustomEvent(SESSION_STORAGE_KEYS.customerAccessToken, {
      detail: loginResult?.customerAccessToken?.accessToken,
    })
  );
};

const useShowErrorAndStopImpersonate = () => {
  const { addToast } = useContext(StoreContext);
  const onStopImpersonate = useOnStopImpersonate();

  return useCallback((message: string) => {
    addToast({
      id: Date.now(),
      message: message,
    });
    setTimeout(async () => {
      await onStopImpersonate();
    }, 5000);
  }, []);
};

const useShowErrorAndLogout = () => {
  const { addToast } = useContext(StoreContext);

  return useCallback((message: string) => {
    addToast({
      id: Date.now(),
      message: message,
    });
    setTimeout(() => {
      msalLogout();
    }, 5000);
  }, []);
};
