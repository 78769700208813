import { isValidPhoneNumber } from "libphonenumber-js";
import { ShopifyCustomer } from "../../services/shopify/types";
import {
  UserErrorExceptionResponse,
  UserErrorType,
  getUserErrorException,
} from "../../exceptions";
import * as yup from "yup";
import { ShopifyCustomerService } from "../../services/shopify/customers.service";

const validateCustomerProfile = async (customer: ShopifyCustomer) => {
  if (!isValidPhoneNumber(customer.phone)) {
    throw getUserErrorException(
      [
        {
          field: ["input", "phone"],
          message: "Enter a valid phone number",
          code: "INVALID",
        },
      ] as UserErrorExceptionResponse,
      customer
    );
  }

  yup
    .string()
    .email()
    .required()
    .validate(customer.email)
    .catch(() => {
      throw getUserErrorException(
        [
          {
            field: ["input", "email"],
            message: "Enter a valid email",
            code: "INVALID",
          },
        ] as UserErrorExceptionResponse,
        customer
      );
    });

  await checkForDuplicateValues({
    email: customer.email,
    phone: customer.phone,
    id: customer.id,
  });
};

const checkForDuplicateValues = async ({
  email,
  phone,
  id,
}: {
  email: string;
  phone: string;
  id?: string;
}) => {
  const errors: UserErrorExceptionResponse = [];
  const shopifyCustomerService = new ShopifyCustomerService();

  if (email) {
    const response = await shopifyCustomerService.getCustomerByEmail(email);
    if (response.customer && response.customer.id !== id) {
      errors.push({
        field: ["input", "email"],
        code: UserErrorType.Taken,
        message: "This email is in use",
      });
    }
  }

  if (phone) {
    const response = await shopifyCustomerService.getCustomerByPhone(phone);
    if (response.customer && response.customer.id !== id) {
      errors.push({
        field: ["input", "phone"],
        code: UserErrorType.Taken,
        message: "This phone is in use",
      });
    }
  }

  if (errors.length) {
    throw getUserErrorException(errors, {
      email,
      phone,
    });
  }
};

export default validateCustomerProfile;
