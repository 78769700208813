import { SESSION_STORAGE_KEYS } from "../constants/storageKeys";
import { CustomerProfileViewModel } from "../types";
import { getSessionStorageItem } from "./utils";

export const getSessionCustomerProfile =
  (): CustomerProfileViewModel | null => {
    return getSessionStorageItem(
      SESSION_STORAGE_KEYS.customerProfileKey
    ) as CustomerProfileViewModel;
  };

export const setSessionCustomerProfile = (
  profile: CustomerProfileViewModel
) => {
  sessionStorage.setItem(
    SESSION_STORAGE_KEYS.customerProfileKey,
    JSON.stringify(profile)
  );
};
