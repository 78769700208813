import { useContext, useEffect } from "react";
import { SESSION_STORAGE_KEYS } from "../../../utils/constants/storageKeys";
import { StoreContext } from "../../store-context";

interface UseCustomerAccessTokenStorageArgs {
  customerAccessToken: string | undefined;
}

export const useCustomerAccessTokenStorage = ({
  customerAccessToken,
}: UseCustomerAccessTokenStorageArgs) => {
  const { setCustomerAccessToken } = useContext(StoreContext);

  useEffect(() => {
    const accessToken = sessionStorage.getItem(
      SESSION_STORAGE_KEYS.customerAccessToken
    );

    if (accessToken) {
      setCustomerAccessToken(accessToken);
    }
  }, []);

  useEffect(() => {
    if (customerAccessToken && customerAccessToken?.length > 0) {
      sessionStorage.setItem(
        SESSION_STORAGE_KEYS.customerAccessToken,
        customerAccessToken
      );
      setCustomerAccessToken(customerAccessToken);
    }
  }, [customerAccessToken]);
};
