import { useContext } from "react";
import {
  LOCAL_STORAGE_KEYS,
  SESSION_STORAGE_KEYS,
} from "../../utils/constants/storageKeys";
import { getSessionStorageItem } from "../../utils/sessionStorage";
import { getPersonId } from "../../utils/helper/accountUtils";
import { StoreContext } from "../../context/store-context";
import { ProfileService } from "../mdrt/profile.service";
import { MembershipService } from "../mdrt/membership.service";
import { ImpersonateResponse } from "../shopify/types";

interface ImpersonateArgs {
  mdrtId: number;
  isAlreadyImpersonating: boolean;
}

interface ImpersonateArgsExtended extends ImpersonateArgs {
  setCustomerAccessToken: (token: string) => void;
}

export const useImpersonation = () => {
  const { setCustomerAccessToken } = useContext(StoreContext);

  return {
    impersonate: async (args: ImpersonateArgs) =>
      await impersonate({
        ...args,
        setCustomerAccessToken,
      }),
    stopImpersonate: async () => await stopImpersonate(setCustomerAccessToken),
  };
};

const impersonate = async ({
  mdrtId,
  isAlreadyImpersonating = false,
  setCustomerAccessToken,
}: ImpersonateArgsExtended): Promise<ImpersonateResponse> => {
  if (!mdrtId || mdrtId < 1 || mdrtId > 2147483647) {
    return {
      success: false,
      message: "Incorrect MDRT Id.",
    };
  }

  const profileService = new ProfileService();
  const personId = (await profileService.getPersonId(mdrtId))?.personID ?? 0;
  if (personId === 0) {
    return {
      success: false,
      message: "User not found.",
    };
  }

  const currentPersonId = getCurrentStaffPersonId();
  if (personId === currentPersonId) {
    return {
      success: false,
      message: "You can't impersonate yourself.",
    };
  }

  const currentImpersonatedId = getCurrentPersonId();
  if (personId === currentImpersonatedId) {
    return {
      success: false,
      message: "You are already impersonating this user.",
    };
  }

  const membershipService = new MembershipService();
  const customer = await membershipService.getMDRTCustomer(personId);
  if (!customer?.mdrtid) {
    return {
      success: false,
      message: "User not found.",
    };
  }
  if (!customer?.primaryEmail?.length) {
    return {
      success: false,
      message: "User doesn't have primary email.",
    };
  }

  if (!isAlreadyImpersonating) {
    sessionStorage.setItem(
      SESSION_STORAGE_KEYS.staffCustomerProfileKey,
      sessionStorage.getItem(SESSION_STORAGE_KEYS.customerProfileKey) ?? ""
    );
    sessionStorage.setItem(
      SESSION_STORAGE_KEYS.staffСustomerAccessToken,
      sessionStorage.getItem(SESSION_STORAGE_KEYS.customerAccessToken) ?? ""
    );
  }
  sessionStorage.setItem(
    SESSION_STORAGE_KEYS.impersonatePersonId,
    personId.toString()
  );
  sessionStorage.removeItem(SESSION_STORAGE_KEYS.customerProfileKey);
  sessionStorage.removeItem(SESSION_STORAGE_KEYS.customerAccessToken);
  setCustomerAccessToken("");
  localStorage.removeItem(LOCAL_STORAGE_KEYS.wishlist);
  sessionStorage.removeItem(SESSION_STORAGE_KEYS.hasGracePeriod);

  return {
    success: true,
    message: "OK",
  };
};

const stopImpersonate = async (
  setCustomerAccessToken: (token: string) => void
): Promise<boolean> => {
  sessionStorage.setItem(
    SESSION_STORAGE_KEYS.customerProfileKey,
    sessionStorage.getItem(SESSION_STORAGE_KEYS.staffCustomerProfileKey) ?? ""
  );
  sessionStorage.setItem(
    SESSION_STORAGE_KEYS.customerAccessToken,
    sessionStorage.getItem(SESSION_STORAGE_KEYS.staffСustomerAccessToken) ?? ""
  );
  setCustomerAccessToken(
    sessionStorage.getItem(
      SESSION_STORAGE_KEYS.staffСustomerAccessToken
    ) as string
  );

  sessionStorage.removeItem(SESSION_STORAGE_KEYS.impersonatePersonId);
  sessionStorage.removeItem(SESSION_STORAGE_KEYS.staffCustomerProfileKey);
  sessionStorage.removeItem(SESSION_STORAGE_KEYS.staffСustomerAccessToken);
  localStorage.removeItem(LOCAL_STORAGE_KEYS.wishlist);
  sessionStorage.removeItem(SESSION_STORAGE_KEYS.hasGracePeriod);

  return true;
};

const getCurrentPersonId = () => {
  const currentImpersonatedId = getPersonId();
  if (!currentImpersonatedId) return;
  return currentImpersonatedId;
};

const getCurrentStaffPersonId = () => {
  const currentStaffProfile = getSessionStorageItem(
    SESSION_STORAGE_KEYS.staffCustomerProfileKey
  );
  if (!currentStaffProfile) return getCurrentPersonId();
  return currentStaffProfile?.personId;
};
