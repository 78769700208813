exports.components = {
  "component---src-pages-401-tsx": () => import("./../../../src/pages/401.tsx" /* webpackChunkName: "component---src-pages-401-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-cart-tsx": () => import("./../../../src/pages/cart.tsx" /* webpackChunkName: "component---src-pages-cart-tsx" */),
  "component---src-pages-checkout-tsx": () => import("./../../../src/pages/checkout.tsx" /* webpackChunkName: "component---src-pages-checkout-tsx" */),
  "component---src-pages-collections-shopify-collection-handle-tsx": () => import("./../../../src/pages/collections/{ShopifyCollection.handle}.tsx" /* webpackChunkName: "component---src-pages-collections-shopify-collection-handle-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-orders-details-tsx": () => import("./../../../src/pages/orders/details.tsx" /* webpackChunkName: "component---src-pages-orders-details-tsx" */),
  "component---src-pages-orders-index-tsx": () => import("./../../../src/pages/orders/index.tsx" /* webpackChunkName: "component---src-pages-orders-index-tsx" */),
  "component---src-pages-policies-privacy-policy-tsx": () => import("./../../../src/pages/policies/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-policies-privacy-policy-tsx" */),
  "component---src-pages-policies-return-policy-tsx": () => import("./../../../src/pages/policies/return-policy.tsx" /* webpackChunkName: "component---src-pages-policies-return-policy-tsx" */),
  "component---src-pages-policies-shipping-policy-tsx": () => import("./../../../src/pages/policies/shipping-policy.tsx" /* webpackChunkName: "component---src-pages-policies-shipping-policy-tsx" */),
  "component---src-pages-policies-terms-and-conditions-tsx": () => import("./../../../src/pages/policies/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-policies-terms-and-conditions-tsx" */),
  "component---src-pages-products-index-tsx": () => import("./../../../src/pages/products/index.tsx" /* webpackChunkName: "component---src-pages-products-index-tsx" */),
  "component---src-pages-products-shopify-product-handle-tsx": () => import("./../../../src/pages/products/{ShopifyProduct.handle}.tsx" /* webpackChunkName: "component---src-pages-products-shopify-product-handle-tsx" */),
  "component---src-pages-profile-tsx": () => import("./../../../src/pages/profile.tsx" /* webpackChunkName: "component---src-pages-profile-tsx" */),
  "component---src-pages-purchases-details-tsx": () => import("./../../../src/pages/purchases/details.tsx" /* webpackChunkName: "component---src-pages-purchases-details-tsx" */),
  "component---src-pages-purchases-index-tsx": () => import("./../../../src/pages/purchases/index.tsx" /* webpackChunkName: "component---src-pages-purchases-index-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-pages-wishlist-tsx": () => import("./../../../src/pages/wishlist.tsx" /* webpackChunkName: "component---src-pages-wishlist-tsx" */)
}

