import { RawAxiosRequestHeaders } from "axios";
import { membershipScopes, msalInstance } from "../authConfig";
import { msalLogout } from "../services/internal/msal";
import sleep from "./sleep";
import { MsalExceptionTypes } from "../exceptions";

export const getMdrtBackendHeaders = async (options?: {
  authorization?: boolean;
  contentType?: string;
}): Promise<RawAxiosRequestHeaders> => {
  const baseHeaders = {
    "Ocp-Apim-Subscription-Key": `${process.env.GATSBY_SUBSCRIPTION_KEY}`,
  };
  const authorization =
    options?.authorization === undefined ? true : options?.authorization;
  const contentType = options?.contentType;

  const getAuthorizationHeaders = async () => {
    if (!authorization) {
      return {};
    }

    try {
      const token = await msalInstance.acquireTokenSilent({
        scopes: membershipScopes,
      });

      return {
        Authorization: `Bearer ${
          token.accessToken && token.accessToken.length > 0
            ? token.accessToken
            : token.idToken
        }`,
      };
    } catch (e: any) {
      if (e.errorCode === MsalExceptionTypes.InteractionRequired) {
        msalLogout();
        await sleep(1000);
      }
      throw e;
    }
  };

  return {
    ...baseHeaders,
    ...(await getAuthorizationHeaders()),
    ...(contentType ? { "Content-Type": contentType } : {}),
  };
};
