import { Product, SelectedOptions } from "../types";
import { MembershipLevels, MemberTypes } from "../constants/constants";
import { PlaqueInfo } from "../../components/Plaque/types";
import { isRingWithInitials } from "../../components/Plaque/utils/commonUtils";

export const plaqueInfoReducer = (state: PlaqueInfo, action: PlaqueAction) => {
  const { type, payload } = action;
  switch (type) {
    case PlaqueActions.SET_PLAQUE_INFO:
      return { ...state, ...payload };
    default:
      return state;
  }
};

export enum PlaqueActions {
  SET_PLAQUE_INFO = "setPlaqueInfo",
}

export type PlaqueAction = {
  type: PlaqueActions;
  payload: PlaqueInfo;
};

export const getMembershipType = (option: SelectedOptions) => {
  const { value } = option;
  switch (value) {
    case MembershipLevels.member:
      return MemberTypes.qualifyingMember;
    case MembershipLevels.COT:
    case MembershipLevels.TOT:
      return null;
    default:
      return value;
  }
};

export const getMembershipLevel = (option: SelectedOptions) => {
  const { value } = option;
  switch (value) {
    case MemberTypes.lifeMember:
    case MemberTypes.qualifyingMember:
      return MembershipLevels.member;
    default:
      return value;
  }
};

export const getIsGrayscale = (
  option: SelectedOptions,
  product: Product
) => {
  const { value } = option;
  return value === "Silver" && isRingWithInitials(product);
};
