import { LOCAL_STORAGE_KEYS } from "../constants/storageKeys";
import { getPersonId } from "./accountUtils";

export const getCurrentUserCheckoutId = () =>
  localStorage.getItem(LOCAL_STORAGE_KEYS.checkoutId(getPersonId()));

export const getAnonymousUserCheckoutId = () =>
  localStorage.getItem(LOCAL_STORAGE_KEYS.checkoutId());

export const setCurrentUserCheckoutId = (checkoutId: string) => {
  localStorage.setItem(
    LOCAL_STORAGE_KEYS.checkoutId(getPersonId()),
    checkoutId
  );
};

export const removeAnonymousUserCheckoutId = () => {
  localStorage.removeItem(LOCAL_STORAGE_KEYS.checkoutId());
};
