export const getRangeFilters = (
  pageSize: number,
  endCursor: string,
  startCursor: string
) => {
  if (endCursor) {
    return `first: ${pageSize} after: "${endCursor}"`;
  } else if (startCursor) {
    return `last: ${pageSize} before: "${startCursor}"`;
  } else {
    return `first: ${pageSize}`;
  }
};
