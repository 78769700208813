import React, { FC } from "react";
import { SvgProps } from "./types";

const CartIcon: FC<SvgProps> = ({ className }) => (
  <svg
    className={className}
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    strokeWidth="2"
  >
    <path
      d="M11.25 27.5a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5ZM23.75 27.5a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5ZM2.5 2.5h3.125l3.9 15.613A2.5 2.5 0 0 0 11.95 20h11.1a2.5 2.5 0 0 0 2.425-1.887L27.5 10h-20"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CartIcon;
