import { useImpersonation } from "../../services/internal/impersonate";
import { pushDataLayerImpersonation } from "./googleAnalytics";

export const useOnStopImpersonate = () => {
  const { stopImpersonate } = useImpersonation();

  return async () => {
    await stopImpersonate();
    pushDataLayerImpersonation(false);
    window.location.href = "/";
  };
};
