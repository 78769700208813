import axios from "axios";
import { CustomerYears, MDRTCustomer } from "./types";
import { getMdrtBackendHeaders } from "../../utils/fetchUtils";

export class MembershipService {
  baseUrl = `${process.env.GATSBY_API}/membership`;

  async getMDRTCustomer(personId: number): Promise<MDRTCustomer> {
    const headers = await getMdrtBackendHeaders();

    const result = await axios
      .get(`${this.baseUrl}/v2/CustomerProfile/${personId}`, {
        headers,
      })
      .then((result) => result.data)
      .then((result: MDRTCustomer) => {
        return {
          ...result,
          fullName: `${result.firstName} ${result.lastName}`.trim(),
          phone: result.phone || `+${result.countryCode}${result.phoneNumber}`,
          designation: [result?.designation1, result?.designation2]
            .filter((d) => d)
            .join(", ")
            .trim(),
        };
      });
    return result;
  }

  async getCustomerHistoryYears(personId: number): Promise<CustomerYears[]> {
    const headers = await getMdrtBackendHeaders();
    return await axios
      .get(`${this.baseUrl}/history/years/${personId}`, {
        headers: headers,
      })
      .then((result) => result.data);
  }

  async getCustomerHistory(personId: number): Promise<{
    endOfGracePeriod: string;
  }> {
    const headers = await getMdrtBackendHeaders();
    return await axios
      .get(`${this.baseUrl}/history/${personId}`, {
        headers,
      })
      .then((result) => result.data);
  }
}
