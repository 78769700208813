import { useEffect } from "react";

// Adds online state logging to see in sentry
export const useOnlineStateLogger = () => {
  useEffect(() => {
    const onOnline = () => console.log("You are back online");
    const onOffline = () => console.error("Internet connection lost detected!");

    window.addEventListener("online", onOnline);
    window.addEventListener("offline", onOffline);

    return () => {
      window.removeEventListener("online", onOnline);
      window.removeEventListener("offline", onOffline);
    };
  }, []);
};
