import {
  getShopifyUserAlreadyExistsException,
  getUserErrorException,
  UserErrorExceptionResponse,
} from "../../../exceptions";
import { MembershipService } from "../../mdrt/membership.service";
import { ShopifyCustomerService } from "../../shopify/customers.service";
import { ShopifyCustomer } from "../../shopify/types";
import { convertToShopifyCustomer } from "./convert-to-shopify-customer";
import { extractAdditionalInfo } from "./extract-additional-info";
import { getShopifyCustomer } from "./get-current-customer";
import getMDRTCustomerPhone from "./get-mdrt-customer-phone";
import validateCustomerProfile from "../../../utils/validators/validateCustomerProfile";

export const createShopifyCustomer = async (
  personId: number,
  userProvidedEmail?: string,
  userProvidedPhone?: string
): Promise<ShopifyCustomer | null> => {
  const membershipService = new MembershipService();
  const [mdrtCustomer, history] = await Promise.all([
    membershipService.getMDRTCustomer(personId),
    membershipService.getCustomerHistoryYears(personId),
  ]);

  if (!mdrtCustomer || !history) {
    return null;
  }

  const shopifyCustomersService = new ShopifyCustomerService();

  await checkForAlreadyExistingUser(personId);

  const customerEmail = userProvidedEmail || mdrtCustomer.primaryEmail;
  const customerPhone = userProvidedPhone || getMDRTCustomerPhone(mdrtCustomer);

  const customer: ShopifyCustomer = {
    ...convertToShopifyCustomer(personId, mdrtCustomer, history),
    phone: customerPhone,
    email: customerEmail,
  };

  await validateCustomerProfile(customer);
  const response = await shopifyCustomersService.createCustomer(customer);

  if (response.userErrors?.length) {
    throw getUserErrorException(
      response.userErrors as UserErrorExceptionResponse,
      customer
    );
  }

  if (response.customer) {
    return extractAdditionalInfo(response.customer);
  } else {
    return null;
  }
};

const checkForAlreadyExistingUser = async (personId: number) => {
  const customer = await getShopifyCustomer(personId);

  if (customer) {
    throw getShopifyUserAlreadyExistsException();
  }
};
