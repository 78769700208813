import { ShopifyCustomerAddress } from "../../services/shopify/types";
import { LevelOfMembership } from "../types";

export const DEFAULT_ORDERS_PER_PAGE = 5;
export const DEFAULT_PRODUCTS_PER_PAGE = 24;
export const DEFAULT_PRODUCT_LINE_ITEMS_PER_PAGE = 10;
export const SEARCH_PAGE_PRODUCTS_QUERY_COUNT = 250;
export const BEST_SELLING_PRODUCTS_QUERY_COUNT = 10;
export const MAX_NAME_AND_DESIGNATION_LENGTH = 35;
export const MAX_NAME_AND_DESIGNATION_LENGTH_CRYSTAL = 24;
export const MAX_NAME_AND_DESIGNATION_LENGTH_BRONZE = 34;
export const MAX_NUMBER_OF_RECIPIENTS = 100;

export enum ProductMetafields {
  year = "Year",
  author = "Author",
  membershipLevel = "Membership level",
  membershipType = "Membership type",
}

export const PRODUCTS_METAFIELDS: string[] = Object.values(ProductMetafields);

export enum MemberTypes {
  nonMember = "Non-Member",
  formerMember = "Former Member",
  qualifyingMember = "Qualifying Member",
  lifeMember = "Life Member",
  qualifyingAndLifeMember = "Qualifying and Life Member",
  lifeMemberOrQualifyingAndLifeMember = "Life Member OR Qualifying and Life Member",
  allMemberType = "All Member Type",
}

export enum MembershipLevels {
  member = "Member",
  COT = "Court of the Table",
  TOT = "Top of the Table",
}

export const PRODUCT_OPTIONS = {
  membershipType: "Membership type",
  membershipLevel: "Membership level",
  membershipAchievement: "Membership achievement",
  yearsOfProduction: "Years of Production",
  yearsOfMembership: "Years of Membership",
};

export enum ProductCustomizations {
  personalDataAndPhoto = "photo, full name, designations",
  personalData = "full name, designations",
  initials = "initials, size",
  size = "size",
}

export const NOT_ENTERED = "(Not Entered)";

export const ELIGIBLE_LEVELS = {
  [LevelOfMembership.TOT]: [
    MembershipLevels.member,
    MembershipLevels.COT,
    MembershipLevels.TOT,
  ],
  [LevelOfMembership.COT]: [MembershipLevels.member, MembershipLevels.COT],
  [LevelOfMembership.MDRT]: [MembershipLevels.member],
};

const ELIGIBLE_TYPES = {
  [MemberTypes.allMemberType]: [
    MemberTypes.allMemberType,
    MemberTypes.qualifyingAndLifeMember,
    MemberTypes.qualifyingMember,
    MemberTypes.lifeMember,
    MemberTypes.nonMember,
  ],
  [MemberTypes.qualifyingAndLifeMember]: [
    MemberTypes.qualifyingAndLifeMember,
    MemberTypes.qualifyingMember,
    MemberTypes.nonMember,
  ],
  [MemberTypes.lifeMember]: [MemberTypes.lifeMember, MemberTypes.nonMember],
  [MemberTypes.qualifyingMember]: [
    MemberTypes.qualifyingMember,
    MemberTypes.nonMember,
  ],
  [MemberTypes.nonMember]: [MemberTypes.nonMember],
};

type MEMBERTYPES = keyof typeof ELIGIBLE_TYPES;

export const getEligibleMemberTypes = (memberType: string) => {
  if (!memberType) {
    return [];
  }

  const eligibleTypes = ELIGIBLE_TYPES[memberType as MEMBERTYPES];

  return eligibleTypes ? [...eligibleTypes] : [MemberTypes.nonMember];
};

export const SORT_KEYS = {
  RELEVANCE: "RELEVANCE",
  PRICE: "PRICE",
  TITLE: "TITLE",
  CREATED: "CREATED",
  BEST_SELLING: "BEST_SELLING",
  ELIGIBILITY: "ELIGIBILITY",
  PROCESSED_AT: "PROCESSED_AT",
  ID: "ID",
  TOTAL_PRICE: "TOTAL_PRICE",
};

export const METAFIELD_KEYS = {
  membershipLevel: "membership_level",
  membershipType: "membership_type",
  parentId: "parent_id",
  featured: "featured",
  minMembershipYears: "min_membership_years",
  maxMembershipYears: "max_membership_years",
  plaqueType: "plaque_type",
  addToMenu: "add_to_menu",
};

export enum PlaqueTypes {
  FIFTY_YEAR = "50 year plaque",
  BLACK_PIANO_BOARD = "Black piano board",
  BLACK_PIANO_DESKTOP = "Black piano desktop",
  CHERRYWOOD = "Cherrywood",
  DESK_NAME_PLATE = "Desk name plate",
  CONTEMPORARY_BLUE_MIRROR = "Contemporary blue mirror",
  CONTEMPORARY_MIRROR = "Contemporary mirror",
  COURT_OF_THE_TABLE_PIANO_BOARD = "Court of the Table piano board",
  COURT_OF_THE_TABLE_SHADOWBOX = "Court of the Table shadowbox",
  COURT_OF_THE_TABLE_YEAR_PLATE = "Court of the Table year plate",
  COURT_OF_THE_TABLE_YEAR_PLATE_INSERT = "Court of the Table year plate insert",
  CURRENT_MEMBERSHIP_YEAR_PLATE = "Current membership year plate",
  HONOR_ROLL = "Honor roll plaque",
  HONOR_ROLL_YEAR_PLATE_INSERT = "Honor roll plate insert",
  CERTIFICATE = "Certificate",
  PREVIOUS_MEMBERSHIP_YEAR_PLATE = "Previous membership year plate",
  QUARTER_CENTURY = "Quarter century plaque",
  QUARTER_CENTURY_YEAR_PLATE_INSERT = "Quarter century year plate insert",
  ROSEWOOD_DESKTOP = "Rosewood desktop plaque",
  SHADOWBOX_SHIELD = "Shadowbox shield plaque",
  SILHOUETTE_TOP_OF_THE_TABLE = "Silhouette Top of the Table plaque",
  SMOKED_GLASS = "Smoked glass plaque",
  TOP_OF_THE_TABLE_DIAMOND_CUT_CRYSTAL = "Top of the Table Diamond Cut Crystal",
  TOP_OF_THE_TABLE_YEAR_PLATE = "Top of the Table year plate",
  TOP_OF_THE_TABLE_YEAR_PLATE_INSERT = "Top of the Table year plate insert",
  TOP_OF_THE_TABLE_ROSEWOOD = "Top of the Table Rosewood Plaque",
  ALUMINUM = "Aluminum",
  MEMBER_CRYSTAL_CUBE = "Member Crystal Cube",
  BRONZE_LIFE_MEMBER = "Bronze Life Member Plaque",
  WHOLE_PERSON = "Whole Person Plaque",
}

export const plaquesWithSignaturesAndYears: Array<PlaqueTypes> = [
  PlaqueTypes.ALUMINUM,
  PlaqueTypes.ROSEWOOD_DESKTOP,
  PlaqueTypes.CONTEMPORARY_BLUE_MIRROR,
  PlaqueTypes.CONTEMPORARY_MIRROR,
  PlaqueTypes.BLACK_PIANO_BOARD,
  PlaqueTypes.BLACK_PIANO_DESKTOP,
  PlaqueTypes.CHERRYWOOD,
  PlaqueTypes.COURT_OF_THE_TABLE_PIANO_BOARD,
  PlaqueTypes.COURT_OF_THE_TABLE_SHADOWBOX,
  PlaqueTypes.SHADOWBOX_SHIELD,
  PlaqueTypes.SILHOUETTE_TOP_OF_THE_TABLE,
  PlaqueTypes.SMOKED_GLASS,
  PlaqueTypes.TOP_OF_THE_TABLE_ROSEWOOD,
];

export enum ProductsWithInitials {
  ROUND_TABLE_RING = "Round Table Ring",
}

export type TemplateType = PlaqueTypes | ProductsWithInitials;

export enum CustomAttributeKeys {
  FULL_NAME = "Full name",
  DESIGNATION = "Designation",
  INITIALS = "Initials",
  YEAR = "Year",
  PRODUCT_TYPE = "Product type",
  RING_SIZE = "Ring size",
  USE_COMMA_AFTER_NAME = "Include comma after name",
  MEMBER_PHOTO = "Member photo",
  UPLOADED_PHOTO = "Uploaded photo",
  PLAQUE_TEMPLATE_URL = "Plaque template url",
  RECIPIENT = "Recipient",
}

export enum CollectionKeys {
  MEETING_PRESENTATIONS = "Meeting Presentations",
}

export const emptyAddress: ShopifyCustomerAddress = {
  firstName: "",
  lastName: "",
  company: "",
  country: "",
  countryCode: "",
  address1: "",
  city: "",
  zip: "",
  province: "",
};

export const MDRT_SEASON_CUTOFF = "-11-01T00:00:00.000Z";
export const MDRT_GRACE_END = "-05-31T23:59:59.999Z";
