import dayjs from "dayjs";
import { MembershipService } from "../../mdrt/membership.service";
import {} from "../../shopify/customers.service";
import { MetafieldsService } from "../../shopify/metafields.service";
import { AdditionalInfo, ShopifyCustomer } from "../../shopify/types";
import { isEqual, pick } from "lodash";
import { convertToShopifyCustomer } from "./convert-to-shopify-customer";
import { MemberTypes } from "../../../utils/constants/constants";
import { getLevelOfMembershipForFormerMember } from "../../../utils/helper/getLevelOfMembershipForFormerMember";
import { SESSION_STORAGE_KEYS } from "../../../utils/constants/storageKeys";

export const syncCurrentCustomerAdditionalInfo = async (
  id: string,
  personId: number,
  additionalInfo: AdditionalInfo
): Promise<AdditionalInfo | null> => {
  const membershipService = new MembershipService();
  const [mdrtCustomer, history] = await Promise.all([
    membershipService.getMDRTCustomer(personId),
    membershipService.getCustomerHistoryYears(personId),
  ]);

  if (!mdrtCustomer || !history) {
    return null;
  }

  const customer: ShopifyCustomer = convertToShopifyCustomer(
    personId,
    mdrtCustomer,
    history
  );

  if (mdrtCustomer.memberType === MemberTypes.formerMember) {
    const result = await membershipService.getCustomerHistory(personId);
    const hasGracePeriod = dayjs().isBefore(
      dayjs(result.endOfGracePeriod).add(1, "day")
    );
    if (hasGracePeriod) {
      sessionStorage.setItem(SESSION_STORAGE_KEYS.hasGracePeriod, "1");
      customer.additionalInfo.levelOfMembership =
        getLevelOfMembershipForFormerMember(customer.additionalInfo);
    }
  }

  const metafieldsService = new MetafieldsService();
  const fields = [
    "levelOfMembership",
    "memberType",
    "memberStatus",
    "history",
    "companyId",
  ];
  const mdrtAdditionalInfo = pick(customer.additionalInfo, fields);
  const shopifyAdditionalInfo = pick(additionalInfo, fields);
  const destAdditionalInfo = additionalInfo
    ? {
        ...additionalInfo,
        ...mdrtAdditionalInfo,
      }
    : {
        ...customer.additionalInfo,
      };
  if (!isEqual(mdrtAdditionalInfo, shopifyAdditionalInfo)) {
    await metafieldsService.setMetafieldsOfCustomer({
      metafields: [
        {
          namespace: "customer",
          key: "additionalInfo",
          ownerId: id,
          type: "json",
          value: JSON.stringify(destAdditionalInfo),
        },
      ],
    });
    return destAdditionalInfo;
  }

  return null;
};
