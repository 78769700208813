import * as Sentry from "@sentry/react";
import { getMdrtBackendHeaders } from "../../utils/fetchUtils";
import sleep from "../../utils/sleep";
import {
  CustomerResponse,
  LoginResponse,
  LoginRequest,
  ShopifyCustomer,
  ShopifyCustomerAddress,
} from "./types";
import { isValidPhoneNumber, parsePhoneNumber } from "libphonenumber-js";
import axios, { RawAxiosRequestHeaders } from "axios";
import { msalInstance } from "../../authConfig";

export class ShopifyCustomerService {
  baseUrl = `${process.env.GATSBY_API}/store/customers`;

  async getCustomerByPersonId(
    personId: number,
    retryCount = 1
  ): Promise<CustomerResponse> {
    const account = msalInstance.getActiveAccount();
    if (!account) {
      return {
        customer: null,
      };
    }
    const headers = await getMdrtBackendHeaders();
    return await this.getCustomerByPersonIdWithRetry(
      headers,
      personId,
      retryCount
    );
  }

  async getCustomerByPersonIdWithRetry(
    headers: RawAxiosRequestHeaders,
    personId: number,
    retryCount: number,
    delay = 0
  ): Promise<CustomerResponse> {
    await sleep(delay);
    const response = await axios
      .get<CustomerResponse>(`${this.baseUrl}/${personId}`, {
        headers,
      })
      .then((result) => result.data)
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.log(e);
        return { customer: null };
      });

    if (!response?.customer && retryCount > 0) {
      return await this.getCustomerByPersonIdWithRetry(
        headers,
        personId,
        retryCount - 1,
        delay + 2000
      );
    } else {
      return response;
    }
  }

  async getCustomerByEmail(email: string): Promise<CustomerResponse> {
    const account = msalInstance.getActiveAccount();
    if (!account) {
      return {
        customer: null,
      };
    }

    const headers = await getMdrtBackendHeaders();
    const response = await axios
      .post(`${this.baseUrl}/getByEmail`, { email }, { headers })
      .then((result) => result.data);

    return response;
  }

  async getCustomerByPhone(phone: string): Promise<CustomerResponse> {
    const account = msalInstance.getActiveAccount();
    if (!account) {
      return {
        customer: null,
      };
    }

    const headers = await getMdrtBackendHeaders();
    const response = await axios
      .post(
        `${this.baseUrl}/getByPhone`,
        {
          phone: isValidPhoneNumber(phone)
            ? parsePhoneNumber(phone).format("E.164")
            : phone,
        },
        { headers }
      )
      .then((result) => result.data);

    return response;
  }

  async createCustomer(customer: ShopifyCustomer): Promise<CustomerResponse> {
    const headers = await getMdrtBackendHeaders();
    const result = await axios
      .post(
        `${this.baseUrl}/create`,
        {
          ...customer,
          phone: isValidPhoneNumber(customer.phone)
            ? parsePhoneNumber(customer.phone).format("E.164")
            : customer.phone,
        },
        { headers }
      )
      .then((result) => result.data)
      .catch((e) => {
        Sentry.captureException(e);
        throw e;
      });
    return result;
  }

  async updateCustomerProfileInfo(
    customer: ShopifyCustomer
  ): Promise<CustomerResponse> {
    const headers = await getMdrtBackendHeaders();
    const result = await axios
      .post(
        `${this.baseUrl}/update`,
        {
          id: customer.id,
          firstName: customer.firstName,
          lastName: customer.lastName,
          email: customer.email,
          phone: isValidPhoneNumber(customer.phone)
            ? parsePhoneNumber(customer.phone).format("E.164")
            : customer.phone,
        },
        { headers }
      )
      .then((result) => result.data);
    return result;
  }

  async updateCustomerAddresses(
    id: string,
    defaultAddress: ShopifyCustomerAddress
  ): Promise<CustomerResponse> {
    const headers = await getMdrtBackendHeaders();
    const result = await axios
      .post(
        `${this.baseUrl}/update`,
        {
          id,
          defaultAddress,
        },
        { headers }
      )
      .then((result) => result.data);
    return result;
  }

  async login(request: LoginRequest): Promise<LoginResponse> {
    const headers = await getMdrtBackendHeaders();
    const result = await axios
      .post(`${this.baseUrl}/login`, request, { headers })
      .then((result) => result.data);
    return result;
  }
}
