export const getSessionStorageItem = (key: string) => {
  const value =
    typeof window !== "undefined" &&
    sessionStorage &&
    sessionStorage.getItem(key);
  if (!value) {
    return null;
  }
  return JSON.parse(value);
};
