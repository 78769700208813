import { AuthError } from "@azure/msal-browser";
import Cookies from "js-cookie";
import {
  loginScopes,
  msalInstance,
  MSAL_USERNAME,
  REDIRECT_TO_HOME_ON_LOGOUT_PATHS,
} from "../../authConfig";
import {
  AVAILABLE_LANGUAGES,
  DEFAULT_LANGUAGE,
} from "../../utils/constants/languages";
import {
  LOCAL_STORAGE_KEYS,
  SESSION_STORAGE_KEYS,
} from "../../utils/constants/storageKeys";
import { getLocalStorageItem } from "../../utils/localStorageUtils";

export const msalLogin = () => {
  msalInstance.loginRedirect({
    scopes: loginScopes,
  });
};

export const msalLogout = (calledByThisInstance = true) => {
  sessionStorage.clear();

  const currentLogoutCount = getLocalStorageItem(
    LOCAL_STORAGE_KEYS.logoutCount
  );
  const newLogoutCount = (Number(currentLogoutCount) + 1).toString();

  if (calledByThisInstance) {
    localStorage.removeItem(LOCAL_STORAGE_KEYS.logoutCount);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.wishlist);
    sessionStorage.setItem(SESSION_STORAGE_KEYS.logoutCount, newLogoutCount);
    localStorage.setItem(LOCAL_STORAGE_KEYS.logoutCount, newLogoutCount);
  } else {
    localStorage.removeItem(LOCAL_STORAGE_KEYS.wishlist);
    sessionStorage.setItem(
      SESSION_STORAGE_KEYS.logoutCount,
      currentLogoutCount
    );
  }

  const postLogoutRedirectUri = REDIRECT_TO_HOME_ON_LOGOUT_PATHS.some((path) =>
    AVAILABLE_LANGUAGES.some((language) => {
      const languagePrefix =
        language === DEFAULT_LANGUAGE ? `` : `/${language}`;
      return window.location.pathname === `${languagePrefix}${path}`;
    })
  )
    ? "/"
    : window.location.href;

  msalInstance.logoutRedirect({ postLogoutRedirectUri });
  sessionStorage.setItem(SESSION_STORAGE_KEYS.msalLogout, "inProgress");
};

export const ssoSilent = async (repeatAmount: number): Promise<boolean> => {
  const userName = Cookies.get(MSAL_USERNAME);
  const silentRequest = userName
    ? { scopes: loginScopes, loginHint: userName }
    : { scopes: loginScopes };
  try {
    await msalInstance.ssoSilent(silentRequest);
    return true;
  } catch (e) {
    const errorcode = (e as AuthError).errorCode ?? "";
    console.log("ssoSilent", errorcode);
    if (repeatAmount > 0 && errorcode === "monitor_window_timeout") {
      ssoSilent(repeatAmount - 1);
    }
    return true;
  }
};
