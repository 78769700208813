import { UserErrorException } from "../../exceptions";

export enum MDRTStoreCreationState {
  closed = "closed",
  creatingCustomer = "creatingCustomer",
  waitingLogin = "waitingLogin",
}

export interface MDRTStoreCreationContext {
  duplicateValues: Partial<Record<AccountFields, string>>;
  initialValues: Partial<Record<AccountFields, string>>;
  showModal: boolean;
  waitingLogin: boolean;
  preventLogin: boolean;
  openModal: (userErrors: UserErrorException) => void;
  closeModal: () => void;
  onCustomerCreated: () => void;
}

export enum AccountFields {
  Email = "email",
  Phone = "phone",
}
