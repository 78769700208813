import { PlaqueOptions } from "./types";
import {
  PlaqueTypes,
  ProductsWithInitials,
} from "../../utils/constants/constants";
import { ACCEPTED_PROPORTION } from "../PhotoUpload/constants/uploadLimitations";

export const PLAQUE_WIDTH = 1000;

export const MEMBER_PHOTO_ASPECT_RATIO =
  ACCEPTED_PROPORTION[1] / ACCEPTED_PROPORTION[0];

export enum PlaqueFonts {
  CLEARFACE = "Clearface Black",
  CLEARFACE_HEAVY = "Clearface Heavy",
  SOUVENIR = "Souvenir",
  ZAPF = "Zapf Chancery",
  TIMES = "Times",
  ARIAL = "Arial",
  BODONI = "Bodoni",
  HELVETICA = "Helvetica",
}

export enum PlaqueFontStyles {
  ITALIC = "italic",
  NORMAL = "normal",
}

const yearPlateOptions = {
  yearInsertPlateHeight: 0.051,
  yearInsertPlateWidth: 0.146,
  yearInsertTextOffset: 0.032,
  yearInsertPositions: {
    rowsY: [0.695, 0.763, 0.833],
    columnsX: [0.22, 0.427, 0.633],
  },
};

export const plaqueOptions: PlaqueOptions[] = [
  {
    name: PlaqueTypes.FIFTY_YEAR,
    color: "#280000",
    colorEffect: "#f9f4e8",
    logoWidth: 0.285,
    logoPosY: 0.17,
    nameWidth: 0.55,
    namePosY: 0.67,
    nameMaxFontSize: 0.045,
    membershipTypeWidth: 0.6,
    membershipTypePosY: 0.575,
    membershipTypeMaxFontSize: 0.04,
    yearWidth: 0.6,
    yearPosY: 0.615,
    yearMaxFontSize: 0.044,
    yearFont: PlaqueFonts.ZAPF,
    signatureWidth: 0.32,
    signaturePosY: 0.69,
    copyrightWidth: 0.55,
    copyrightPosY: 0.8,
  },
  {
    name: PlaqueTypes.BLACK_PIANO_BOARD,
    color: "#c9c2b4",
    colorEffect: "#0D0906",
    marginLeft: 0.19,
    logoWidth: 0.15,
    logoPosY: 0.175,
    nameWidth: 0.33,
    namePosY: 0.5,
    nameMaxFontSize: 0.033,
    nameYOffset: 0.035,
    membershipTypeWidth: 0.33,
    membershipTypePosY: 0.565,
    membershipTypeMaxFontSize: 0.028,
    membershipTypeFont: PlaqueFonts.CLEARFACE,
    membershipTypeFontStyle: PlaqueFontStyles.ITALIC,
    yearWidth: 0.33,
    yearPosY: 0.61,
    yearMaxFontSize: 0.028,
    yearFont: PlaqueFonts.CLEARFACE,
    yearFontStyle: PlaqueFontStyles.ITALIC,
    yearYOffset: 0.025,
    signatureWidth: 0.28,
    signaturePosY: 0.63,
    copyrightWidth: 0.17,
    copyrightPosY: 0.765,
    photoWidth: 0.32,
    photoPosY: 0.12,
    photoPosX: 0.18,
  },
  {
    name: PlaqueTypes.CHERRYWOOD,
    color: "#fff4c9",
    colorEffect: "#3C1817",
    marginLeft: 0.165,
    logoWidth: 0.125,
    logoPosY: 0.25,
    nameWidth: 0.3,
    namePosY: 0.512,
    nameMaxFontSize: 0.026,
    nameYOffset: 0.025,
    membershipTypeWidth: 0.3,
    membershipTypePosY: 0.555,
    membershipTypeMaxFontSize: 0.024,
    membershipTypeFont: PlaqueFonts.CLEARFACE,
    membershipTypeFontStyle: PlaqueFontStyles.ITALIC,
    yearWidth: 0.3,
    yearPosY: 0.595,
    yearMaxFontSize: 0.024,
    yearFont: PlaqueFonts.CLEARFACE,
    yearFontStyle: PlaqueFontStyles.ITALIC,
    yearYOffset: 0.025,
    signatureWidth: 0.2,
    signaturePosY: 0.625,
    copyrightWidth: 0.14,
    copyrightPosY: 0.725,
    photoWidth: 0.265,
    photoPosY: 0.191,
    photoPosX: 0.23,
  },
  {
    name: PlaqueTypes.SHADOWBOX_SHIELD,
    color: "#0A0E0F",
    colorEffect: "",
    logoWidth: 0.22,
    logoPosY: 0.225,
    nameWidth: 0.5,
    namePosY: 0.52,
    nameMaxFontSize: 0.043,
    membershipTypeWidth: 0.5,
    membershipTypePosY: 0.575,
    membershipTypeMaxFontSize: 0.035,
    membershipTypeFont: PlaqueFonts.CLEARFACE,
    membershipTypeFontStyle: PlaqueFontStyles.ITALIC,
    yearWidth: 0.5,
    yearPosY: 0.605,
    yearMaxFontSize: 0.035,
    yearFontStyle: PlaqueFontStyles.ITALIC,
    signatureWidth: 0.19,
    signaturePosY: 0.64,
    copyrightWidth: 0.225,
    copyrightPosY: 0.73,
  },
  {
    name: PlaqueTypes.COURT_OF_THE_TABLE_PIANO_BOARD,
    color: "#FFCE8C",
    colorEffect: "#754512",
    logoWidth: 0.32,
    logoPosY: 0.185,
    nameWidth: 0.6,
    namePosY: 0.585,
    nameMaxFontSize: 0.05,
    yearWidth: 0.5,
    yearPosY: 0.63,
    yearMaxFontSize: 0.045,
    signatureWidth: 0.3,
    signaturePosY: 0.69,
    copyrightWidth: 0.57,
    copyrightPosY: 0.8,
  },
  {
    name: PlaqueTypes.SILHOUETTE_TOP_OF_THE_TABLE,
    color: "#EBEDE1",
    colorEffect: "#00051F",
    logoWidth: 0.235,
    logoPosY: 0.241,
    nameWidth: 0.5,
    namePosY: 0.583,
    nameMaxFontSize: 0.045,
    membershipTypeWidth: 0.5,
    membershipTypePosY: 0.57,
    membershipTypeMaxFontSize: 0.035,
    yearWidth: 0.5,
    yearPosY: 0.615,
    yearMaxFontSize: 0.035,
    signatureWidth: 0.3,
    signaturePosY: 0.67,
    copyrightWidth: 0.49,
    copyrightPosY: 0.775,
  },
  {
    name: PlaqueTypes.CONTEMPORARY_BLUE_MIRROR,
    color: "#070705",
    colorEffect: "#E9E9E9",
    logoWidth: 0.28,
    logoPosY: 0.19,
    logoYOffset: 0.022,
    nameWidth: 0.55,
    namePosY: 0.523,
    nameMaxFontSize: 0.05,
    nameYOffset: 0.033,
    membershipTypeWidth: 0.55,
    membershipTypePosY: 0.585,
    membershipTypeMaxFontSize: 0.05,
    yearWidth: 0.55,
    yearPosY: 0.63,
    yearMaxFontSize: 0.043,
    yearYOffset: 0.025,
    signatureWidth: 0.35,
    signaturePosY: 0.67,
    copyrightWidth: 0.6,
    copyrightPosY: 0.8,
  },
  {
    name: PlaqueTypes.COURT_OF_THE_TABLE_SHADOWBOX,
    color: "#D7C194",
    colorEffect: "#000C12",
    logoWidth: 0.19,
    logoPosY: 0.28,
    nameWidth: 0.3,
    namePosY: 0.56,
    nameMaxFontSize: 0.033,
    yearWidth: 0.3,
    yearPosY: 0.59,
    yearMaxFontSize: 0.03,
    signatureWidth: 0.23,
    signaturePosY: 0.63,
    copyrightWidth: 0.36,
    copyrightPosY: 0.725,
  },
  {
    name: PlaqueTypes.CONTEMPORARY_MIRROR,
    color: "#F4F0ED",
    colorEffect: "#000000",
    logoWidth: 0.28,
    logoPosY: 0.19,
    logoYOffset: 0.022,
    nameWidth: 0.55,
    namePosY: 0.523,
    nameMaxFontSize: 0.05,
    nameYOffset: 0.033,
    membershipTypeWidth: 0.55,
    membershipTypePosY: 0.585,
    membershipTypeMaxFontSize: 0.05,
    yearWidth: 0.55,
    yearPosY: 0.63,
    yearMaxFontSize: 0.043,
    yearYOffset: 0.025,
    signatureWidth: 0.35,
    signaturePosY: 0.67,
    copyrightWidth: 0.6,
    copyrightPosY: 0.8,
  },
  {
    name: PlaqueTypes.TOP_OF_THE_TABLE_DIAMOND_CUT_CRYSTAL,
    transform: [1, -0.05, 0, 1, 1, 0, 0],
    marginLeft: -0.03,
    color: "#e1dfe5",
    strokeColor: "#9b9ca2",
    logoWidth: 0.36,
    logoXOffset: 0.025,
    logoPosY: 0.14,
    logoYOffset: 0,
    logoColorEffect: "#9b9ca2",
    nameFont: PlaqueFonts.BODONI,
    nameWidth: 0.63,
    namePosY: 0.465,
    nameMaxFontSize: 0.05,
    membershipLevelFont: PlaqueFonts.BODONI,
    membershipLevelWidth: 0.63,
    membershipLevelPosY: 0.521,
    membershipLevelMaxFontSize: 0.05,
    yearFont: PlaqueFonts.BODONI,
    yearWidth: 0.63,
    yearPosY: 0.575,
    yearMaxFontSize: 0.045,
  },
  {
    name: PlaqueTypes.TOP_OF_THE_TABLE_YEAR_PLATE,
    color: "#D1B88F",
    colorEffect: "#141411",
    logoWidth: 0.33,
    logoPosY: 0.14,
    nameWidth: 0.47,
    namePosY: 0.635,
    nameMaxFontSize: 0.045,
    membershipTypeWidth: 0.55,
    membershipTypePosY: 0.585,
    membershipTypeMaxFontSize: 0.043,
    yearWidth: 0.55,
    yearPosY: 0.63,
    yearMaxFontSize: 0.038,
    copyrightWidth: 0.7,
    copyrightPosY: 0.53,
    ...yearPlateOptions,
  },
  {
    name: PlaqueTypes.TOP_OF_THE_TABLE_ROSEWOOD,
    color: "#eadfa9",
    colorEffect: "#231814",
    logoWidth: 0.255,
    logoPosY: 0.145,
    logoXOffset: 0.011,
    nameFont: PlaqueFonts.BODONI,
    nameWidth: 0.65,
    namePosY: 0.51,
    nameMaxFontSize: 0.048,
    yearWidth: 0.9,
    yearPosY: 0.596,
    yearFont: PlaqueFonts.BODONI,
    yearMaxFontSize: 0.04,
    signatureWidth: 0.675,
    signaturePosY: 0.605,
    copyrightWidth: 0.6,
    copyrightPosY: 0.784,
  },
  {
    name: PlaqueTypes.COURT_OF_THE_TABLE_YEAR_PLATE,
    color: "#D7D9D1",
    colorEffect: "#141411",
    logoWidth: 0.33,
    logoPosY: 0.14,
    nameWidth: 0.47,
    namePosY: 0.635,
    nameMaxFontSize: 0.045,
    membershipTypeWidth: 0.55,
    membershipTypePosY: 0.585,
    membershipTypeMaxFontSize: 0.043,
    yearWidth: 0.55,
    yearPosY: 0.63,
    yearMaxFontSize: 0.038,
    copyrightWidth: 0.7,
    copyrightPosY: 0.53,
    ...yearPlateOptions,
  },
  {
    name: PlaqueTypes.HONOR_ROLL,
    color: "#FDFCF3",
    colorEffect: "#070027",
    logoWidth: 0.26,
    logoPosY: 0.177,
    nameWidth: 0.55,
    namePosY: 0.65,
    nameMaxFontSize: 0.045,
    membershipTypeWidth: 0.55,
    membershipTypePosY: 0.585,
    membershipTypeMaxFontSize: 0.043,
    yearWidth: 0.55,
    yearPosY: 0.533,
    yearMaxFontSize: 0.05,
    signatureWidth: 0.3,
    signaturePosY: 0.69,
    copyrightWidth: 0.6,
    copyrightPosY: 0.8,
  },
  {
    name: PlaqueTypes.QUARTER_CENTURY,
    color: "#FDFCF3",
    colorEffect: "#070027",
    logoWidth: 0.26,
    logoPosY: 0.177,
    nameWidth: 0.55,
    namePosY: 0.65,
    nameMaxFontSize: 0.045,
    membershipTypeWidth: 0.55,
    membershipTypePosY: 0.585,
    membershipTypeMaxFontSize: 0.043,
    yearWidth: 0.55,
    yearPosY: 0.533,
    yearMaxFontSize: 0.05,
    signatureWidth: 0.3,
    signaturePosY: 0.69,
    copyrightWidth: 0.6,
    copyrightPosY: 0.8,
  },
  {
    name: PlaqueTypes.BLACK_PIANO_DESKTOP,
    color: "#121512",
    colorEffect: "#E9E9E9",
    logoWidth: 0.29,
    logoPosY: 0.13,
    logoYOffset: 0.01,
    nameWidth: 0.6,
    namePosY: 0.49,
    nameMaxFontSize: 0.063,
    nameYOffset: 0.07,
    membershipTypeWidth: 0.6,
    membershipTypePosY: 0.57,
    membershipTypeMaxFontSize: 0.06,
    yearWidth: 0.55,
    yearPosY: 0.625,
    yearYOffset: 0.015,
    yearMaxFontSize: 0.05,
    signatureWidth: 0.4,
    signaturePosY: 0.67,
    copyrightWidth: 0.73,
    copyrightPosY: 0.85,
  },
  {
    name: PlaqueTypes.DESK_NAME_PLATE,
    transform: [1, -0.06, 0.25, 1, -5, 0],
    color: "#F2CA9A",
    colorEffect: "#12100B",
    marginLeft: 0.055,
    nameWidth: 0.63,
    namePosY: 0.54,
    nameMaxFontSize: 0.05,
    membershipTypeWidth: 0.55,
    membershipTypePosY: 0.575,
    membershipTypeMaxFontSize: 0.043,
    yearWidth: 0.63,
    yearPosY: 0.7,
    yearMaxFontSize: 0.045,
  },
  {
    name: PlaqueTypes.ROSEWOOD_DESKTOP,
    color: "#121512",
    colorEffect: "#F3DCAE",
    logoWidth: 0.31,
    logoPosY: 0.125,
    logoYOffset: 0.02,
    nameWidth: 0.65,
    namePosY: 0.495,
    nameYOffset: 0.057,
    nameMaxFontSize: 0.063,
    membershipTypeWidth: 0.6,
    membershipTypePosY: 0.575,
    membershipTypeMaxFontSize: 0.06,
    yearWidth: 0.55,
    yearPosY: 0.625,
    yearMaxFontSize: 0.055,
    yearYOffset: 0.015,
    signatureWidth: 0.4,
    signaturePosY: 0.7,
    copyrightWidth: 0.73,
    copyrightPosY: 0.85,
  },
  {
    name: PlaqueTypes.SMOKED_GLASS,
    color: "#3E3834",
    colorEffect: "#DADFCB",
    logoWidth: 0.283,
    logoPosY: 0.16,
    logoYOffset: -0.02,
    nameWidth: 0.55,
    namePosY: 0.53,
    nameMaxFontSize: 0.05,
    nameYOffset: 0.06,
    membershipTypeWidth: 0.55,
    membershipTypePosY: 0.585,
    membershipTypeMaxFontSize: 0.055,
    yearWidth: 0.55,
    yearPosY: 0.63,
    yearMaxFontSize: 0.045,
    signatureWidth: 0.4,
    signaturePosY: 0.68,
    copyrightWidth: 0.57,
    copyrightPosY: 0.825,
  },
  {
    name: PlaqueTypes.HONOR_ROLL_YEAR_PLATE_INSERT,
    color: "#FDFCF3",
    colorEffect: "#333232",
    yearWidth: 0.9,
    yearPosY: 0.66,
    yearMaxFontSize: 0.4,
  },
  {
    name: PlaqueTypes.QUARTER_CENTURY_YEAR_PLATE_INSERT,
    color: "#FDFCF3",
    colorEffect: "#333232",
    yearWidth: 0.9,
    yearPosY: 0.66,
    yearMaxFontSize: 0.4,
  },
  {
    name: PlaqueTypes.COURT_OF_THE_TABLE_YEAR_PLATE_INSERT,
    color: "#D7D9D1",
    colorEffect: "#231814",
    yearWidth: 0.9,
    yearPosY: 0.64,
    yearFont: PlaqueFonts.SOUVENIR,
    yearMaxFontSize: 0.22,
  },
  {
    name: PlaqueTypes.TOP_OF_THE_TABLE_YEAR_PLATE_INSERT,
    color: "#D1B88F",
    colorEffect: "#231814",
    yearWidth: 0.9,
    yearPosY: 0.64,
    yearFont: PlaqueFonts.SOUVENIR,
    yearMaxFontSize: 0.22,
  },
  {
    name: PlaqueTypes.CERTIFICATE,
    color: "#191817",
    colorEffect: "",
    marginLeft: 0.2753,
    nameWidth: 0.7,
    namePosY: 0.459,
    nameMaxFontSize: 0.027,
    nameFont: PlaqueFonts.TIMES,
    nameFontStyle: PlaqueFontStyles.ITALIC,
    nameFontWeight: "bold",
    nameYOffset: 0.06,
    membershipTypeWidth: 0.22,
    membershipTypePosY: 0.23,
    membershipTypeMaxFontSize: 0.016,
    membershipTypeFont: PlaqueFonts.TIMES,
    membershipTypeFontWeight: "bold",
    membershipTypeXOffset: 0.37,
    yearWidth: 0.55,
    yearPosY: 0.615,
    yearMaxFontSize: 0.026,
    yearFont: PlaqueFonts.TIMES,
    yearFontStyle: PlaqueFontStyles.ITALIC,
    signatureWidth: 0.215,
    signaturePosY: 0.79,
    signatureXOffset: 0.37,
  },
  {
    name: PlaqueTypes.CURRENT_MEMBERSHIP_YEAR_PLATE,
    color: "#836A41",
    colorEffect: "#FEF6DC",
    yearWidth: 0.9,
    yearPosY: 0.64,
    yearFont: PlaqueFonts.ARIAL,
    yearMaxFontSize: 0.22,
  },
  {
    name: PlaqueTypes.PREVIOUS_MEMBERSHIP_YEAR_PLATE,
    color: "#836A41",
    colorEffect: "#FEF6DC",
    yearWidth: 0.9,
    yearPosY: 0.64,
    yearFont: PlaqueFonts.ARIAL,
    yearMaxFontSize: 0.22,
  },
  {
    name: ProductsWithInitials.ROUND_TABLE_RING,
    color: "#312A1A",
    colorEffect: "#FEFFFD",
    marginLeft: 0.1153,
    logoWidth: 0.161,
    logoPosY: 0.3649,
    nameWidth: 0.2,
    namePosY: 0.429,
    nameMaxFontSize: 0.062,
    nameFont: PlaqueFonts.SOUVENIR,
    nameXOffset: 0.19,
  },
  {
    name: PlaqueTypes.ALUMINUM,
    color: "#100E11",
    colorEffect: "",
    logoWidth: 0.16,
    logoPosY: 0.237,
    nameWidth: 0.45,
    namePosY: 0.455,
    nameMaxFontSize: 0.043,
    nameFont: PlaqueFonts.BODONI,
    membershipTypeWidth: 0.5,
    membershipTypePosY: 0.51,
    membershipTypeMaxFontSize: 0.035,
    membershipTypeFont: PlaqueFonts.BODONI,
    yearWidth: 0.5,
    yearPosY: 0.56,
    yearMaxFontSize: 0.035,
    yearFont: PlaqueFonts.BODONI,
    signatureWidth: 0.3,
    signaturePosY: 0.6,
    copyrightWidth: 0.45,
    copyrightPosY: 0.69,
  },
  {
    name: PlaqueTypes.MEMBER_CRYSTAL_CUBE,
    color: "#D5DAD8",
    strokeColor: "#c2c2c2",
    logoColorEffect: "#bdbdbd",
    marginLeft: 0.0155,
    logoWidth: 0.212,
    logoPosY: 0.251,
    nameWidth: 0.57,
    namePosY: 0.516,
    nameMaxFontSize: 0.038,
    nameFont: PlaqueFonts.BODONI,
    nameFontWeight: "bold",
    membershipTypeFont: PlaqueFonts.BODONI,
    membershipTypeFontWeight: "bold",
    membershipTypePosY: 0.568,
    membershipTypeMaxFontSize: 0.038,
    membershipTypeWidth: 0.45,
    membershipLevelFont: PlaqueFonts.BODONI,
    membershipLevelFontWeight: "bold",
    membershipLevelPosY: 0.568,
    membershipLevelMaxFontSize: 0.038,
    membershipLevelWidth: 0.45,
    yearWidth: 0.5,
    yearPosY: 0.62,
    yearMaxFontSize: 0.038,
    yearFont: PlaqueFonts.BODONI,
    yearFontWeight: "bold",
    clippingMask: [
      { x: 497, y: 202 },
      { x: 842, y: 535 },
      { x: 611, y: 757 },
      { x: 397, y: 753 },
      { x: 163, y: 524 },
      { x: 497, y: 202 },
    ],
  },
  {
    name: PlaqueTypes.BRONZE_LIFE_MEMBER,
    color: "#181818",
    logoWidth: 0.195,
    logoPosY: 0.181,
    logoXOffset: -0.012,
    nameWidth: 0.5,
    namePosY: 0.52,
    nameMaxFontSize: 0.063,
    nameFont: PlaqueFonts.BODONI,
    nameFontWeight: 700,
    nameFontColor: "#f7de9c",
    membershipTypeFont: PlaqueFonts.BODONI,
    membershipTypeColor: "#f7de9c",
    membershipTypePosY: 0.57,
    membershipTypeMaxFontSize: 0.05,
    membershipTypeWidth: 0.25,
    yearWidth: 0.1,
    yearMaxFontSize: 0.03,
    yearFont: PlaqueFonts.BODONI,
    yearFontWeight: 300,
    yearInsertPositions: {
      rowsY: [0.6, 0.645, 0.69, 0.736, 0.781, 0.826],
      columnsX: [0.222, 0.362, 0.502, 0.641],
    },
    yearInsertPlateWidth: 0.119,
    yearInsertPlateHeight: 0.035,
    yearInsertTextOffset: 0.022,
    yearInsertPlateColor: "#f7de9c",
  },
  {
    name: PlaqueTypes.WHOLE_PERSON,
    color: "#f4d097",
    nameWidth: 0.514,
    namePosY: 0.323,
    nameMaxFontSize: 0.034,
    nameFont: PlaqueFonts.TIMES,
    nameFontWeight: "bold",
  },
];
