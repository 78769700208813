import { LOCAL_STORAGE_KEYS } from "../../utils/constants/storageKeys";
import { getLocalStorageItem } from "../../utils/localStorageUtils";
import { CustomerProfileViewModel } from "../../utils/types";
import { getSessionCustomerProfile } from "../../utils/sessionStorage";
import { MetafieldsService } from "../shopify/metafields.service";
import { ShopifyCustomer } from "../shopify/types";

export const toggleWishlist = (
  productId: string,
  setWishlist: (ids: string[]) => void
) => {
  const customer = getSessionCustomerProfile() as CustomerProfileViewModel;
  if (!customer) {
    return;
  }
  const wishlist = getWishlist();
  const index = wishlist.indexOf(productId);
  if (index > -1) {
    wishlist.splice(index, 1);
  } else {
    wishlist.push(productId);
  }
  localStorage.setItem(LOCAL_STORAGE_KEYS.wishlist, JSON.stringify(wishlist));
  const service = new MetafieldsService();
  service.setMetafieldsOfCustomer({
    metafields: [
      {
        namespace: "customer",
        key: "wishlist",
        ownerId: customer.id,
        type: "list.product_reference",
        value: JSON.stringify(wishlist),
      },
    ],
  });
  setWishlist(wishlist);
};

export const getWishlist = () => {
  return getLocalStorageItem(LOCAL_STORAGE_KEYS.wishlist) ?? [];
};

export const setWishlistToStorage = (
  customer: ShopifyCustomer,
  setWishlist: (ids: string[]) => void
) => {
  const customerWishlistValue = customer?.metafields?.nodes?.find(
    (x) => x.key === "wishlist"
  )?.value;
  const wishlist = customerWishlistValue
    ? JSON.parse(customerWishlistValue)
    : [];
  localStorage.setItem(LOCAL_STORAGE_KEYS.wishlist, JSON.stringify(wishlist));
  window.dispatchEvent(new CustomEvent(LOCAL_STORAGE_KEYS.wishlist));
  setWishlist(wishlist);
};
