import React, { FC, ReactNode } from "react";
import { MsalProvider } from "@azure/msal-react";
import { msalInstance } from "../../authConfig";
import {
  useCustomerAccessTokenStorage,
  useCustomerDataStorage,
  useLogin,
  useLogoutCountStorage,
} from "./hooks";

export const MsalAuthenticationProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  useLogoutCountStorage();

  const { shopifyCustomer, customerAccessToken } = useLogin();

  useCustomerDataStorage({ shopifyCustomer });

  useCustomerAccessTokenStorage({ customerAccessToken });

  return <MsalProvider instance={msalInstance}>{children}</MsalProvider>;
};
