import { useEffect } from "react";
import { getLocalStorageItem } from "../../../utils/localStorageUtils";
import {
  LOCAL_STORAGE_KEYS,
  SESSION_STORAGE_KEYS,
} from "../../../utils/constants/storageKeys";
import { getSessionStorageItem } from "../../../utils/sessionStorage";
import { msalLogout } from "../../../services/internal/msal";

export const useLogoutCountStorage = () => {
  useEffect(() => {
    const localStorageLogoutCount = getLocalStorageItem(
      LOCAL_STORAGE_KEYS.logoutCount
    );

    if (localStorageLogoutCount === null) {
      localStorage.setItem(LOCAL_STORAGE_KEYS.logoutCount, "0");
      sessionStorage.setItem(SESSION_STORAGE_KEYS.logoutCount, "0");
    } else {
      sessionStorage.setItem(
        SESSION_STORAGE_KEYS.logoutCount,
        localStorageLogoutCount
      );
    }

    window.addEventListener("storage", (storageEvent: StorageEvent) => {
      if (storageEvent.key === LOCAL_STORAGE_KEYS.logoutCount) {
        const newValue = storageEvent.newValue;
        const thisTabValue = getSessionStorageItem(
          SESSION_STORAGE_KEYS.logoutCount
        );

        if (newValue !== thisTabValue) msalLogout(false);
      }
    });
  }, []);
};
