import { MsalExceptionTypes } from "../../../exceptions";
import { getSessionCustomerProfile } from "../../../utils/sessionStorage";
import { ShopifyCustomerService } from "../../shopify/customers.service";
import { ShopifyCustomer } from "../../shopify/types";
import { msalLogout } from "../msal";

export const getCurrentShopifyCustomer =
  async (): Promise<ShopifyCustomer | null> => {
    const profile = getSessionCustomerProfile();
    if (!profile) {
      return null;
    }

    return getShopifyCustomer(profile.personId);
  };

export const getShopifyCustomer = async (
  personId: number,
  retryCount: number = 0
): Promise<ShopifyCustomer | null> => {
  try {
    const shopifyCustomersService = new ShopifyCustomerService();
    const result = await shopifyCustomersService.getCustomerByPersonId(
      personId,
      retryCount
    );
    return result?.customer;
  } catch (e: any) {
    if (e.errorCode === MsalExceptionTypes.InteractionRequired) {
      msalLogout();
    }
    throw e;
  }
};
