export const AVAILABLE_LANGUAGES = [
  `en`,
  `id`,
  `es`,
  `vi`,
  `th`,
  `zh-cht`,
  `zh-cn`,
  `ko`,
  `ja`,
];

export const DEFAULT_LANGUAGE = "en";
