import React, {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useState,
} from "react";
import { MDRTStoreCreationContext, MDRTStoreCreationState } from "./types";
import { UserErrorException, UserErrorType } from "../../exceptions";

const defaultValues: MDRTStoreCreationContext = {
  duplicateValues: {},
  initialValues: {},
  showModal: false,
  waitingLogin: false,
  preventLogin: false,
  openModal: () => {},
  closeModal: () => {},
  onCustomerCreated: () => {},
};

const Context = createContext<MDRTStoreCreationContext>(defaultValues);

export const useMDRTStoreCreationContext = () => {
  const context = useContext(Context);

  if (!context) {
    throw new Error(
      `${useMDRTStoreCreationContext.name} must be used within the ${MDRTStoreCreationContextProvider.name}`
    );
  }

  return context;
};

export const MDRTStoreCreationContextProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const [duplicateValues, setDuplicateValues] = useState(
    defaultValues.duplicateValues
  );
  const [initialValues, setInitialValues] = useState(
    defaultValues.initialValues
  );
  const [state, setState] = useState(MDRTStoreCreationState.closed);

  const showModal = state !== MDRTStoreCreationState.closed;
  const waitingLogin = state === MDRTStoreCreationState.waitingLogin;
  const preventLogin = state === MDRTStoreCreationState.creatingCustomer;

  const openModal = (userErrors: UserErrorException) => {
    const values: MDRTStoreCreationContext["duplicateValues"] = {};

    if (
      userErrors.data.email &&
      userErrors.data.email.code === UserErrorType.Taken
    ) {
      values.email = userErrors.request.email;
    }
    if (
      userErrors.data.phone &&
      userErrors.data.phone.code === UserErrorType.Taken
    ) {
      values.phone = userErrors.request.phone;
    }

    setDuplicateValues(values);
    setInitialValues({
      email: userErrors.request.email,
      phone: userErrors.request.phone,
    });
    setState(MDRTStoreCreationState.creatingCustomer);
  };

  const closeModal = () => {
    setDuplicateValues({});
    setState(MDRTStoreCreationState.closed);
  };

  const onCustomerCreated = () => {
    setState(MDRTStoreCreationState.waitingLogin);
  };

  return (
    <Context.Provider
      value={{
        duplicateValues,
        initialValues,
        showModal,
        waitingLogin,
        preventLogin,
        openModal,
        closeModal,
        onCustomerCreated,
      }}
    >
      {children}
    </Context.Provider>
  );
};
