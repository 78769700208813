import * as Sentry from "@sentry/gatsby";
import { CustomHttpClient } from "./src/utils/sentryCustomHttpClient";

Sentry.init({
  dsn: process.env.GATSBY_SENTRY_DSN,
  environment: process.env.GATSBY_SENTRY_ENV,
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 0.2,
  sendDefaultPii: true,
  integrations: [
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
      maskAllInputs: false,
      networkDetailAllowUrls: [/.+/],
    }),
    new CustomHttpClient({
      failedRequestStatusCodes: [[400, 599]],
    }),
  ],
  beforeBreadcrumb: (breadcrumb, hint) => {
    if (
      breadcrumb.category === "fetch" &&
      hint?.response?.status &&
      hint.response.status >= 400
    ) {
      breadcrumb.data = {
        ...breadcrumb.data,
        responseStatusCode: hint.response.status,
      };
    }

    if (
      breadcrumb.category === "xhr" &&
      hint?.xhr?.status &&
      hint.xhr.status >= 400
    ) {
      breadcrumb.data = {
        ...breadcrumb.data,
        responseStatusCode: hint.xhr.status,
        xhrResponse: hint.xhr.response,
      };
    }

    return breadcrumb;
  },
});
