export const MAX_FILE_SIZE_BYTES = parseInt(
  process.env.GATSBY_MAX_IMAGE_UPLOAD_FILE_SIZE ?? "1000000"
);
export const ACCEPTED_FILE_FORMATS = ["png", "jpg", "jpeg"];
export const ACCEPTED_FILE_FORMATS_MIME = ["image/jpeg", "image/png"];
export const MAX_FILES = 1;
export const ACCEPTED_PROPORTION = [4, 6];

export const MAX_FILE_SIZE_MB = MAX_FILE_SIZE_BYTES / 1000000;
export const ACCEPTED_FILE_FORMATS_UPPER_CASE = ACCEPTED_FILE_FORMATS.map(
  (format) => format.toUpperCase()
);

export const MAX_DIMENSION = 4470;
