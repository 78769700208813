import { MDRTCustomer } from "../../mdrt/types";

const getMDRTCustomerPhone = (mdrtCustomer: MDRTCustomer) => {
  if (mdrtCustomer.countryCode && mdrtCustomer.phoneNumber) {
    return `+${mdrtCustomer.countryCode}${mdrtCustomer.phoneNumber}`;
  } else {
    return mdrtCustomer.phoneNumber || mdrtCustomer.phone;
  }
};

export default getMDRTCustomerPhone;
