import { plaqueOptions } from "../constants";
import { PlaqueOptions } from "../types";
import {
  MembershipLevels,
  PlaqueTypes,
  ProductsWithInitials,
  TemplateType,
} from "../../../utils/constants/constants";
import { Product } from "../../../types/internal.types";

export const isPlaqueWithYearSumInsert = (type: TemplateType) =>
  type === PlaqueTypes.HONOR_ROLL || type === PlaqueTypes.QUARTER_CENTURY;

export const isPlaqueWithMultipleYearInserts = (type: TemplateType) =>
  type === PlaqueTypes.COURT_OF_THE_TABLE_YEAR_PLATE ||
  type === PlaqueTypes.TOP_OF_THE_TABLE_YEAR_PLATE ||
  type === PlaqueTypes.BRONZE_LIFE_MEMBER;

export const isPlaqueWithMembershipType = (type: TemplateType) =>
  type === PlaqueTypes.BLACK_PIANO_BOARD ||
  type === PlaqueTypes.CONTEMPORARY_BLUE_MIRROR ||
  type === PlaqueTypes.CONTEMPORARY_MIRROR ||
  type === PlaqueTypes.SHADOWBOX_SHIELD ||
  type === PlaqueTypes.BLACK_PIANO_DESKTOP ||
  type === PlaqueTypes.CHERRYWOOD ||
  type === PlaqueTypes.ROSEWOOD_DESKTOP ||
  type === PlaqueTypes.SMOKED_GLASS ||
  type === PlaqueTypes.ALUMINUM ||
  type === PlaqueTypes.MEMBER_CRYSTAL_CUBE ||
  type === PlaqueTypes.BRONZE_LIFE_MEMBER;

export const isPlaqueWithMemberPhoto = (type: TemplateType) =>
  type === PlaqueTypes.BLACK_PIANO_BOARD || type === PlaqueTypes.CHERRYWOOD;

export const isYearSumPlateInsert = (type: TemplateType) =>
  type === PlaqueTypes.QUARTER_CENTURY_YEAR_PLATE_INSERT ||
  type === PlaqueTypes.HONOR_ROLL_YEAR_PLATE_INSERT;

export const isYearPlateInsert = (type: TemplateType) =>
  type === PlaqueTypes.COURT_OF_THE_TABLE_YEAR_PLATE_INSERT ||
  type === PlaqueTypes.TOP_OF_THE_TABLE_YEAR_PLATE_INSERT;

export const isCertificate = (type: TemplateType) =>
  type === PlaqueTypes.CERTIFICATE;

export const isLeftAligned = (type: TemplateType) =>
  type === PlaqueTypes.CERTIFICATE;

export const isNameUpperCase = (type: TemplateType) =>
  type !== PlaqueTypes.CERTIFICATE &&
  type !== PlaqueTypes.TOP_OF_THE_TABLE_DIAMOND_CUT_CRYSTAL &&
  type !== PlaqueTypes.MEMBER_CRYSTAL_CUBE;

export const isMembershipTypeUpperCase = (type: TemplateType) =>
  type === PlaqueTypes.BRONZE_LIFE_MEMBER;

export const getOptionValue = (
  type: TemplateType,
  optionKey: keyof PlaqueOptions
) => {
  const options = plaqueOptions.find((option) => option.name === type);

  return options && (options[optionKey] as any);
};

export const getColor = (type: TemplateType) => getOptionValue(type, "color");

export const hasMembershipLevel = (
  membershipLevel: MembershipLevels | undefined | null
) =>
  membershipLevel === MembershipLevels.COT ||
  membershipLevel === MembershipLevels.TOT;

export const isRingWithInitials = (product: Product) =>
  product.title === ProductsWithInitials.ROUND_TABLE_RING;

export const getEvenNumber = (number: number) => {
  if (!number) return number;
  return number % 2 === 0 ? number : number + 1;
};

export const getPlaqueIncludedYearPlateCount = (type: PlaqueTypes) => {
  switch (type) {
    case PlaqueTypes.BRONZE_LIFE_MEMBER:
      return 10;
    default:
      return 1;
  }
};

export const getPlaqueTotalYearInsertCount = (type: PlaqueTypes) => {
  switch (type) {
    case PlaqueTypes.BRONZE_LIFE_MEMBER:
      return 24;
    default:
      return 1;
  }
};

export const isPlaqueWithMultipleIncludedYearPlates = (type: PlaqueTypes) =>
  type === PlaqueTypes.BRONZE_LIFE_MEMBER;

export const isPlaqueWithYearSelector = (type: PlaqueTypes) =>
  type !== PlaqueTypes.WHOLE_PERSON &&
  type !== PlaqueTypes.FIFTY_YEAR &&
  type !== PlaqueTypes.DESK_NAME_PLATE &&
  type !== PlaqueTypes.HONOR_ROLL &&
  type !== PlaqueTypes.HONOR_ROLL_YEAR_PLATE_INSERT &&
  type !== PlaqueTypes.QUARTER_CENTURY &&
  type !== PlaqueTypes.QUARTER_CENTURY_YEAR_PLATE_INSERT &&
  type !== PlaqueTypes.BRONZE_LIFE_MEMBER;
