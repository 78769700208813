import axios from "axios";
import { Country, Designation } from "./types";
import { getMdrtBackendHeaders } from "../../utils/fetchUtils";

export class ProfileService {
  baseUrl = `${process.env.GATSBY_API}/profile`;

  async getCountries(): Promise<Country[]> {
    const headers = await getMdrtBackendHeaders({ authorization: false });
    const result = await axios
      .get(`${this.baseUrl}/countries`, {
        headers: headers,
      })
      .then((response) => response.data)
      .then(
        (
          response: {
            country: string;
            ISOCode: string;
            telephoneCode: string;
          }[]
        ) => {
          return response.map((item) => ({
            country: item.country,
            countryCode: item.ISOCode,
            phoneCode: item.telephoneCode,
          }));
        }
      );
    return result;
  }

  async getDesignations(): Promise<Designation[]> {
    const headers = await getMdrtBackendHeaders({ authorization: false });
    const result = await axios
      .get(`${this.baseUrl}/designations`, {
        headers: headers,
      })
      .then((response) => response.data);
    return result;
  }

  async getPersonId(mdrtId: number): Promise<{ personID: number }> {
    const headers = await getMdrtBackendHeaders({ authorization: false });
    const result = await axios
      .get(`${this.baseUrl}/profile/getpersonidbymdrtid/${mdrtId}`, {
        headers: headers,
      })
      .then((response) => response.data);
    return result;
  }
}
