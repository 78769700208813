import { ShopifyCustomer } from "../../shopify/types";

export const extractAdditionalInfo = (
  customer: ShopifyCustomer
): ShopifyCustomer => {
  const additionalInfoStr = customer.metafields?.nodes.find(
    (node) => node.key === "additionalInfo"
  )?.value;
  if (!additionalInfoStr) {
    return customer;
  }
  return {
    ...customer,
    additionalInfo: JSON.parse(additionalInfoStr),
  };
};
