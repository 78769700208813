import axios from "axios";
import { getMdrtBackendHeaders } from "../../utils/fetchUtils";
import { MetafieldRemoveRequest, MetafieldsSetRequest } from "./types";

export class MetafieldsService {
  baseUrl = `${process.env.GATSBY_API}/store/metafields`;

  async setMetafieldsOfCustomer(req: MetafieldsSetRequest): Promise<void> {
    const headers = await getMdrtBackendHeaders();
    await axios.post(`${this.baseUrl}/set_of_customer`, req, { headers });
  }

  async removeMetafieldOfCustomer(req: MetafieldRemoveRequest): Promise<void> {
    const headers = await getMdrtBackendHeaders();
    await axios.post(`${this.baseUrl}/remove_of_customer`, req, { headers });
  }
}
