import { AdditionalInfo } from "../../services/shopify/types";
import { MembershipLevels, MemberTypes } from "../constants/constants";
import { LevelOfMembership } from "../types";

export const getLevelOfMembershipForFormerMember = (
  additionalInfo: AdditionalInfo
): string => {
  if (
    additionalInfo.levelOfMembership.length ||
    additionalInfo.memberType !== MemberTypes.formerMember
  ) {
    return additionalInfo.levelOfMembership;
  }

  const lastYear = new Date().getFullYear() - 1;
  const lastYearHistoryItems = additionalInfo.history.filter(
    (item) => +item.year === lastYear
  );

  if (lastYearHistoryItems.find((item) => item.name === MembershipLevels.TOT)) {
    return LevelOfMembership.TOT;
  }

  if (lastYearHistoryItems.find((item) => item.name === MembershipLevels.COT)) {
    return LevelOfMembership.COT;
  }

  if (lastYearHistoryItems.length > 0) {
    return LevelOfMembership.MDRT;
  }
  return "";
};
