import { ReactElement } from "react";

export interface ToastInfo {
  id: number;
  message: string;
  icon?: ReactElement;
  variant?: ToastVariant;
}

export enum ToastVariant {
  success = "success",
  error = "error",
}
