import { useContext } from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { StoreContext } from "../context/store-context";
import { ExceptionTypes } from "./exceptionTypes";
import {
  DomainException,
  UserErrorException,
  UserErrorExceptionResponse,
} from "./types";
import { UserError } from "../services/shopify/types";

export const useGenericErrorMessageToast = () => {
  const { t } = useTranslation();
  const { addToast } = useContext(StoreContext);

  return () => {
    addToast({
      id: Date.now(),
      message: t("An unexpected error has occurred."),
    });
  };
};

export const getUserErrorException = (
  response: UserErrorExceptionResponse,
  requestBody: Record<string, any>
): UserErrorException => {
  const data: Record<string, UserError> = {};

  response.forEach((error) => {
    data[error.field[1]] = {
      code: error.code,
      field: error.field[1],
      message: error.message,
    };
  });

  return {
    type: ExceptionTypes.UserError,
    data,
    message: "",
    request: requestBody,
  };
};

export const getShopifyUserAlreadyExistsException = (): DomainException => {
  return {
    type: ExceptionTypes.ShopifyUserAlreadyExists,
    message: "Store user already exists",
  };
};
