import { useMsal } from "@azure/msal-react";
import { msalInstance } from "../../authConfig";
import { SESSION_STORAGE_KEYS } from "../constants/storageKeys";
import { UserRoles } from "../constants/userRoles";
import { getSessionCustomerProfile } from "../sessionStorage";
import { isPositiveNumber } from "./numberUtils";

export const useMsalAccount = () => {
  const { accounts } = useMsal();
  return accounts[0];
};

export const getIsLoggedIn = () => {
  const account = useMsalAccount();
  return !!account;
};

export const useIsMdrtStaff = () => {
  const account = useMsalAccount();
  const groups = (account?.idTokenClaims?.groups ?? []) as string[];
  return groups.includes(UserRoles.MdrtStaff);
};

export const useCanMakeGroupPurchase = () => {
  const account = useMsalAccount();
  const groups = (account?.idTokenClaims?.groups ?? []) as string[];

  if (groups.includes(UserRoles.MdrtStaff)) {
    return true;
  } else if (!groups.includes(UserRoles.StoreBulkOrders)) {
    return false;
  }

  const profile = getSessionCustomerProfile();
  return isPositiveNumber(profile?.companyId);
};

export const getPersonId = (): number => {
  const id = sessionStorage.getItem(SESSION_STORAGE_KEYS.impersonatePersonId);
  if (id?.length && +id > 0) {
    return +id;
  } else {
    const activeAccount = msalInstance.getActiveAccount();
    return (activeAccount?.idTokenClaims?.personId as number) ?? 0;
  }
};
