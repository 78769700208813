import { UserError } from "../services/shopify/types";
import { ExceptionTypes } from "./exceptionTypes";

export interface DomainException {
  type: ExceptionTypes;
  message?: string;
  data?: any;
}

export enum UserErrorType {
  Taken = "TAKEN",
  Invalid = "INVALID",
}

export interface UserErrorExceptionResponseItem {
  code: UserErrorType | string;
  field: string[];
  message: string;
}

export type UserErrorExceptionResponse = Array<UserErrorExceptionResponseItem>;

export interface UserErrorException extends DomainException {
  data: Record<string, UserError>;
  request: Record<string, any>;
}
