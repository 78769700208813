import { useEffect } from "react";
import { CartItem, ShopCartPriceV2, } from "../types";
import { kebabCase } from "lodash";
import { Product, ProductData, Variant } from "../../types/internal.types";

const DATA_LAYER_PUSH_DELAY = 200;

export const pushDataLayerUserInfo = (mdrtId?: unknown, profile?: any) =>
  window.dataLayer.push({
    user_id: mdrtId,
    designation: profile?.designation,
    full_name: profile?.fullName,
    level_of_membership: profile?.levelOfMembership,
    member_status: profile?.memberStatus,
    member_type: profile?.memberType,
    person_id: profile?.personId,
  });

export const pushDataLayerImpersonation = (isImpersonating: boolean) =>
  window.dataLayer.push({
    impersonation: isImpersonating,
  });

export const useDataLayerPushViewItemList = (
  listName: string,
  products: Product[],
  isLoading = false,
  currentPage?: number,
  isPromotion = false
) =>
  useEffect(() => {
    if (!isLoading && !!products.length) {
      pushViewItemList(products, listName, isPromotion);
    }
  }, [isLoading, listName, currentPage]);

export const useDataLayerPushViewItemListInView = (
  listName: string,
  products: Product[],
  isLoading = false,
  currentPage?: number,
  isPromotion = false
) =>
  useEffect(() => {
    const INTERSECTION_RATIO = 0.8;
    const list = document.getElementById(kebabCase(listName));

    const handleVisible = (observerEntries: IntersectionObserverEntry[]) => {
      if (observerEntries[0].intersectionRatio >= INTERSECTION_RATIO) {
        pushViewItemList(products, listName, isPromotion);
        if (list) observer.unobserve(list);
      }
    };

    const observer = new IntersectionObserver(handleVisible, {
      threshold: INTERSECTION_RATIO,
    });

    if (!isLoading && !!products?.length && list) {
      observer.observe(list);
    }
  }, [isLoading, listName, currentPage]);

const pushViewItemList = (
  products: Product[],
  listName: string,
  isPromotion = false
) =>
  setTimeout(() => {
    const event = isPromotion ? "view_promotion" : "view_item_list";
    pushDataLayer(event, {
      item_list_name: !isPromotion ? listName : undefined,
      promotion_name: isPromotion ? listName : undefined,
      items: products.map((product, index) =>
        formatItem(
          product,
          product?.variants?.[0],
          listName,
          undefined,
          index,
          isPromotion
        )
      ),
    });
  }, DATA_LAYER_PUSH_DELAY);

export const useDataLayerPushViewItem = (
  product: Product,
  variant: Variant
) =>
  useEffect(() => {
    setTimeout(() => {
      pushDataLayer("view_item", {
        currency: product?.priceRangeV2?.minVariantPrice?.currencyCode,
        value: variant?.price,
        items: [formatItem(product, variant)],
      });
    }, DATA_LAYER_PUSH_DELAY);
  }, [product, variant]);

export const pushDataLayerSelectItem = (
  listName: string,
  product: Product,
  variant?: Variant,
  isPromotion = false
) => {
  const event = isPromotion ? "select_promotion" : "select_item";
  pushDataLayer(event, {
    item_list_name: !isPromotion ? listName : undefined,
    promotion_name: isPromotion ? listName : undefined,
    items: [
      formatItem(product, variant, listName, undefined, undefined, isPromotion),
    ],
  });
};

export const pushDataLayerSelectPromotionCollection = (
  promotionName: string,
  products: Product[]
) =>
  pushDataLayer("select_promotion", {
    promotion_name: promotionName,
    items: products.map((product, index) =>
      formatItem(product, undefined, promotionName, undefined, index, true)
    ),
  });

export const pushDataLayerWishlist = (
  product: Product,
  variant: Variant,
  isLiked: boolean
) =>
  pushDataLayer(isLiked ? "add_to_wishlist" : "remove_from_wishlist", {
    currency: product?.priceRangeV2?.minVariantPrice?.currencyCode,
    value: variant?.price,
    items: [formatItem(product, variant, "Wishlist")],
  });

export const pushDataLayerRemoveToWishlist = (
  product: ProductData,
  variant: Variant
) =>
  pushDataLayer("add_to_wishlist", {
    currency: product?.priceRangeV2?.minVariantPrice?.currencyCode,
    value: variant?.price,
    items: [formatItem(product, variant, "Wishlist")],
  });

export const pushDataLayerAddToCart = (
  product: Product,
  variant: Variant,
  quantity: number
) =>
  pushDataLayer("add_to_cart", {
    currency: product?.priceRangeV2?.minVariantPrice?.currencyCode,
    value: (variant?.price ?? 0) * quantity,
    items: [formatItem(product, variant, undefined, quantity)],
  });

export const pushDataLayerAddRemoveCartItem = (
  product: CartItem,
  isAdd: boolean,
  quantity?: number
) =>
  pushDataLayer(isAdd ? "add_to_cart" : "remove_from_cart", {
    currency: product?.variant?.priceV2?.currencyCode,
    value: (+product?.variant?.priceV2?.amount ?? 0) * (quantity ?? 0),
    items: [formatCartItem(product, undefined, quantity)],
  });

export const useDataLayerPushViewCart = (
  products: CartItem[],
  subtotal?: ShopCartPriceV2
) =>
  useEffect(() => {
    setTimeout(() => {
      pushDataLayer("view_cart", formatCartItemList(products, subtotal));
    }, DATA_LAYER_PUSH_DELAY);
  }, [products.length]);

export const pushDataLayerBeginCheckout = (
  products: CartItem[],
  subtotal?: ShopCartPriceV2
) => pushDataLayer("begin_checkout", formatCartItemList(products, subtotal));

export const pushDataLayerAddShippingInfo = (
  products: CartItem[],
  subtotal?: ShopCartPriceV2
) => pushDataLayer("add_shipping_info", formatCartItemList(products, subtotal));

const pushDataLayer = (event: string, ecommerce: unknown) => {
  window.dataLayer.push({ ecommerce: null, search_term: null });
  window.dataLayer.push({
    event,
    ecommerce,
  });
};

const formatItem = (
  product: Product,
  variant?: Variant,
  listName?: string,
  quantity?: number,
  index?: number,
  isPromotion = false
) => ({
  item_id: variant?.sku,
  item_name: product?.title,
  item_variant: variant?.title,
  currency: product?.priceRangeV2?.minVariantPrice?.currencyCode,
  discount: getDiscount(variant?.price, variant?.compareAtPrice),
  item_category: product?.productType,
  item_list_name: listName,
  price: variant?.price,
  quantity: quantity,
  index: index,
  promotion_name: isPromotion ? listName : undefined,
});

const formatCartItemList = (
  products: CartItem[],
  subtotal?: ShopCartPriceV2
) => ({
  currency: subtotal?.currencyCode,
  value: Number(subtotal?.amount),
  items: products.map((product, index) => formatCartItem(product, index)),
});

const formatCartItem = (
  product: CartItem,
  index?: number,
  quantity?: number
) => ({
  item_id: product?.variant?.sku,
  item_name: product?.title,
  item_variant: product?.variant?.title,
  currency: product?.variant?.priceV2?.currencyCode,
  discount: getDiscount(
    Number(product?.variant?.price),
    product?.variant?.compareAtPrice
  ),
  price: product?.variant?.priceV2.amount,
  quantity: quantity || product?.quantity,
  index,
});

export const pushDataLayerSearch = (searchTerm: string) => {
  window.dataLayer.push({
    event: "search",
    ecommerce: null,
    search_term: searchTerm,
  });
};

const getDiscount = (price?: number, compareAtPrice?: number | null) =>
  price && compareAtPrice ? compareAtPrice - price : undefined;
