import { useContext, useEffect } from "react";
import * as Sentry from "@sentry/react";
import { setWishlistToStorage } from "../../../services/internal/wishlist";
import { ShopifyCustomer } from "../../../services/shopify/types";
import { SESSION_STORAGE_KEYS } from "../../../utils/constants/storageKeys";
import { pushDataLayerUserInfo } from "../../../utils/helper/googleAnalytics";
import { setSessionCustomerProfile } from "../../../utils/sessionStorage";
import { StoreContext } from "../../store-context";

interface UseCustomerDataStorageArgs {
  shopifyCustomer: ShopifyCustomer | undefined;
}

export const useCustomerDataStorage = ({
  shopifyCustomer,
}: UseCustomerDataStorageArgs) => {
  const { setCustomerProfile, setWishlist } = useContext(StoreContext);

  useEffect(() => {
    if (!shopifyCustomer) {
      pushDataLayerUserInfo();
      return;
    }

    const mdrtId = shopifyCustomer.tags
      ?.find((tag) => tag.startsWith("MDRT"))
      ?.replace("MDRT ID: ", "");

    const profile = {
      ...shopifyCustomer.additionalInfo,
      id: shopifyCustomer.id ?? "",
      personId:
        +shopifyCustomer.personId || +shopifyCustomer.additionalInfo.personId,
      mdrtId: mdrtId ? +mdrtId : 0,
      mainFullName: `${shopifyCustomer.firstName ?? "(Not Entered)"} ${
        shopifyCustomer.lastName
      }`.trim(),
    };

    setSessionCustomerProfile(profile);
    window.dispatchEvent(
      new CustomEvent(SESSION_STORAGE_KEYS.customerProfileKey, {
        detail: profile,
      })
    );
    setCustomerProfile(profile);
    setWishlistToStorage(shopifyCustomer, setWishlist);
    pushDataLayerUserInfo(mdrtId, profile);
    Sentry.setUser({ id: mdrtId });
  }, [shopifyCustomer]);
};
