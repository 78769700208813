import { PublicClientApplication } from "@azure/msal-browser";
import { createClient } from "urql";
import { SHOPIFY_STOREFRONT } from "./services/shopify/api.constants";
import { INTERNAL_LINKS } from "./utils/constants/internalLinks";

const isBrowser = typeof window !== "undefined";
export const msalConfig = {
  auth: {
    clientId: process.env.GATSBY_MSAL_CLIENT_ID ?? "",
    authority: process.env.GATSBY_MSAL_AUTHORITY ?? "",
    knownAuthorities: [process.env.GATSBY_MSAL_KNOWN_AUTHORITY ?? ""],
    redirectUri: isBrowser ? window.location.origin : "/",
    postLogoutRedirectUri: "/",
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    iframeHashTimeout: +(process.env.GATSBY_IFRAMEHASHTIMEOUT ?? 30000),
    allowRedirectInIframe: true,
  },
};

export const MSAL_USERNAME = "msal_username";

export const loginScopes = ["openid", "offline_access"];

export const profileScopes = [
  `${process.env.GATSBY_SCOPES_PREFIX}/Profile.Read`,
  `${process.env.GATSBY_SCOPES_PREFIX}/Profile.Impersonate`,
];

export const membershipScopes = [
  `${process.env.GATSBY_SCOPES_PREFIX}/Membership.Read`,
  `${process.env.GATSBY_SCOPES_PREFIX}/Membership.Impersonate`,
];

export const msalInstance = new PublicClientApplication(msalConfig);
msalInstance
  .handleRedirectPromise()
  .then((tokenResponse) => {
    // Check if the tokenResponse is null
    // If the tokenResponse !== null, then you are coming back from a successful authentication redirect.
    // If the tokenResponse === null, you are not coming back from an auth redirect.
    if (tokenResponse?.account) {
      msalInstance.setActiveAccount(tokenResponse.account);
    }
  })
  .catch((error) => {
    console.log("handleRedirectPromise error", error);
  });

export const urqlClient = createClient({
  url: SHOPIFY_STOREFRONT.url,
  fetchOptions: {
    headers: SHOPIFY_STOREFRONT.headers,
  },
});

export const REDIRECT_TO_HOME_ON_LOGOUT_PATHS = [
  INTERNAL_LINKS.profile,
  INTERNAL_LINKS.orders,
  `${INTERNAL_LINKS.orders}/details/`,
];
