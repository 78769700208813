import sleep from "./sleep";

export const wrapPromiseWithRetries = async <T>(
  promise: Promise<T>,
  retries = 2,
  sleepForMsAfterFail = 500
): Promise<T> => {
  try {
    return await promise;
  } catch (error) {
    if (retries > 0) {
      await sleep(sleepForMsAfterFail);
      return await wrapPromiseWithRetries(promise, retries - 1);
    } else {
      throw error;
    }
  }
};
